define("adept-iq/classes/polylines/routes/scheduled/iq", ["exports", "adept-iq/classes/polylines/routes/scheduled/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    isActive: true,
    isHidden: false,
    points: Ember.computed.alias('iqRoute.scheduledPolyline.coords'),
    inProgress: false,
    // local properties
    polylineService: null,
    record: null,
    iqRoute: Ember.computed.alias('record'),

    init() {
      this._super(...arguments);

      this.visibilityListener();
    },

    visibilityListener: Ember.observer('iqRoute.isPerformed', 'isHidden', 'isJoined', function () {
      if (Ember.isPresent(this.get('points')) || this.get('inProgress')) return;
      const route = this.get('iqRoute'); //Draw Route-Polyline only if the route has Trips

      if (!Ember.isPresent(route) || !Ember.isPresent(route.get('trips')) || route.get('trips.length') === 0) {
        return;
      }

      this.set('inProgress', true);
      this.get('polylineService.fetchScheduledPolyline').perform(route);
    }),
    bemapCoords: Ember.computed('points', function () {
      const points = this.get('points') || [];
      return points.map(point => {
        return {
          lat: point[0],
          lon: point[1]
        };
      });
    })
  });

  _exports.default = _default;
});