define("adept-iq/pods/components/sandbox-setup/step1/component", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDownloaded: false,
    isUploaded: false,
    // Computed property to determine if the upload button should be disabled
    isUploadDisabled: Ember.computed('isDownloaded', 'isUploaded', function () {
      return !this.isDownloaded || this.isUploaded;
    }),

    didInsertElement() {
      this._super(...arguments); // Add click event listener to the document


      document.addEventListener('click', this.addBoundingBox.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments); // Remove click event listener to avoid memory leaks


      document.removeEventListener('click', this.addBoundingBox.bind(this));
    },

    // Determine which button to highlight based on order of task to complete
    addBoundingBox(event) {
      let containerToHighlight = null;

      if (!this.isDownloaded) {
        containerToHighlight = this.element.querySelector('#download-container');
      } else if (!this.isUploadDisabled) {
        containerToHighlight = this.element.querySelector('#upload-container');
      }

      const isDownloadButtonClick = event.target.closest('#download-button');
      const isUploadButtonClick = event.target.closest('#upload-button'); //Don't draw bounding box on button click

      if (isDownloadButtonClick && !this.isDownloaded || isUploadButtonClick && !this.isUploadDisabled) {
        return;
      }

      if (containerToHighlight) {
        containerToHighlight.classList.add('bounding-box'); // Remove the bounding box after a short delay

        setTimeout(() => {
          containerToHighlight.classList.remove('bounding-box');
        }, 400);
      }
    },

    get iconPaths() {
      return {
        sandbox: _iconPaths.default.actionMenu.sandbox,
        upload: _iconPaths.default.actionMenu.upload,
        download: _iconPaths.default.actionMenu.download,
        checkmark: _iconPaths.default.actionMenu.checkmark
      };
    },

    checkTasksCompletion() {
      if (this.get('isDownloaded') && this.get('isUploaded')) {
        // Notify parent component that both tasks are completed
        this.onTaskComplete();
      }
    },

    actions: {
      handleDownloadSuccess() {
        this.set('isDownloaded', true);
        this.checkTasksCompletion();
      },

      handleUploadSuccess() {
        this.set('isUploaded', true);
        this.checkTasksCompletion();
      }

    }
  });

  _exports.default = _default;
});