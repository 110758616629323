define("adept-iq/pods/login/route", ["exports", "lodash", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _lodash, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    beforeModel() {
      const urlParams = new URLSearchParams(window.location.search);
      const ssoToken = urlParams.get('token');
      const failureRedirectUrl = urlParams.get('failureRedirectUrl');

      if (ssoToken) {
        window.history.replaceState({}, document.title, window.location.pathname);
        return this.authenticateWithToken(ssoToken, failureRedirectUrl);
      }
    },

    authenticateWithToken(ssoToken, failureRedirectUrl) {
      const session = this.get('session');
      const authenticator = 'authenticator:sso-external';
      return session.authenticate(authenticator, ssoToken).then(() => {
        this.transitionTo('index');
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.error('Authentication Failed:', error);

        if (failureRedirectUrl) {
          window.location.href = decodeURIComponent(failureRedirectUrl);
        } else {
          this.transitionTo('login');
        }
      });
    },

    setupController(controller
    /*, model*/
    ) {
      controller.set('data', {});
    },

    actions: {
      login(userName, password) {
        const session = this.get('session');
        const authenticator = 'authenticator:sso';
        this.get('flashMessages').clearMessages();
        session.authenticate(authenticator, userName, password).then(() => {
          this.transitionTo().abort();
          localStorage.setItem('currentUser', userName);
          const userDash = localStorage.getItem('userDash');

          if (!userDash) {
            localStorage.setItem('userDash', `${userName}*null`);
            localStorage.removeItem('lastDashboardId');
          } else {
            const arr = userDash.split(',');

            const user = _lodash.default.find(arr, users => {
              return users.split('*')[0] === userName;
            });

            if (!user) {
              arr.push(`${userName}*null`);
              localStorage.setItem('userDash', arr);
              localStorage.removeItem('lastDashboardId');
            } else {
              localStorage.setItem('lastDashboardId', user.split('*')[1]);
            }
          }

          this.transitionTo('index');
        }).catch(e => {
          let message;
          let errorMessage = e.message;

          if (errorMessage.includes('Unexpected response from ADEPT4 adapter:')) {
            errorMessage = 'InternalServerError';
          }

          switch (errorMessage) {
            case 'Unauthorized':
              message = 'Email or password is not correct.';
              break;

            case 'Disabled':
              message = 'Your account is disabled. Please contact your system administrator.';
              break;

            case 'InternalServerError':
              message = 'An error occurred while connecting to ADEPT 4, please try again. If the problem persists, contact an administrator for assistance.';
              break;

            case 'MissingWorkspacePermission':
              message = 'Unable to sign on due to missing workspace permission to assigned role. Contact administrator';
              break;

            default:
              message = errorMessage;
          }

          if (Ember.isPresent(message)) {
            return this.set('controller.data.errors', [message]);
          }

          this.transitionTo().abort();
          throw e;
        });
      }

    }
  });

  _exports.default = _default;
});