define("adept-iq/serializers/route", ["exports", "adept-iq/serializers/-raasService", "adept-iq/utils/api-response-transformer"], function (_exports, _raasService, _apiResponseTransformer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      return payload;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const normalizedData = _apiResponseTransformer.default.transformRouteResponse(payload, id);

      let jsonApiPayload = null;

      if (requestType === 'findRecord') {
        if (normalizedData.length > 0) {
          const record = normalizedData[0];
          jsonApiPayload = {
            data: {
              id: record.id,
              type: primaryModelClass.modelName,
              attributes: record
            }
          };
        }
      } else {
        jsonApiPayload = {
          data: normalizedData.map(record => ({
            id: record.id,
            type: primaryModelClass.modelName,
            attributes: record
          }))
        };
      }

      const normalizedResponse = this._super(store, primaryModelClass, jsonApiPayload, id, requestType);

      return normalizedResponse;
    }

  });

  _exports.default = _default;
});