define("adept-iq/models/metric", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    routes: attr('number'),
    // Count of Scheduled Routes Generated
    unusedRoutes: attr('number'),
    scheduledTrips: attr('number'),
    unscheduledTrips: attr('number'),
    revenueHours: attr('number'),
    revenueMiles: attr('number'),
    totalHours: attr('number'),
    totalMiles: attr('number'),
    schedule: belongsTo('schedule', {
      async: false
    }),
    objective: attr('number'),
    strategyName: Ember.computed('objective', function () {
      const objective = this.get('objective');

      if (!objective) {
        return null;
      }

      const availableStrategies = this.store.peekAll('engine-strategy');

      const sortedStrategies = _lodash.default.orderBy(availableStrategies.filter(_ref => {
        let {
          id
        } = _ref;
        return id !== 'default';
      }), ['id'], ['desc']);

      return _lodash.default.get(sortedStrategies, `[${objective - 1}].displayName`, 'N/A');
    })
  });

  _exports.default = _default;
});