define("adept-iq/pods/components/user-profile/change-credentials/component", ["exports", "adept-iq/config/api-urls", "adept-iq/mixins/password-validation"], function (_exports, _apiUrls, _passwordValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_passwordValidation.default, {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    userDetails: Ember.computed(() => ({
      password: null
    })),
    isPasswordVisible: false,
    validationEnabled: true,
    canSave: Ember.computed('passwordUnchanged', 'isPasswordValid', 'validationEnabled', function () {
      return !this.passwordUnchanged && (this.isPasswordValid || !this.validationEnabled);
    }),
    disableDeleteBtn: false,
    userName: null,
    companyName: null,

    init() {
      this._super(...arguments);

      this.set('displayError', false);
      this.set('data', {});
      const username = localStorage.getItem('currentUser');
      const companyname = localStorage.getItem('userTenantDisplayName');
      this.set('userName', username);
      this.set('companyName', companyname);
      this.resetCurrentSchedule = this.resetCurrentSchedule.bind(this);
      this.filterTextInput = this.filterTextInput.bind(this);
    },

    filterTextInput(event) {
      const alphanumericAndSpecial = /[A-Za-z0-9~!@#$%^&*()_\-+={};:'"|\\<>,.?/]/;
      var keyChar = String.fromCharCode(event.which || event.keyCode);
      return alphanumericAndSpecial.test(keyChar);
    },

    async resetCurrentSchedule() {
      const workspace = this.workspace;
      workspace.set('currentSchedule', null);
    },

    actions: {
      togglePasswordVisibility() {
        this.set('isPasswordVisible', !this.get('isPasswordVisible'));
      },

      changePassword(password) {
        const ajax = this.get('ajax');
        const session = this.get('session');
        const notifications = this.get('notifications');
        const {
          host
        } = _apiUrls.API.ssoService;
        const username = localStorage.getItem('currentUser');
        const userId = localStorage.getItem('currentUserId');
        const url = `${host}/basicUser/${userId}`;
        const data = {
          username: username,
          password: password,
          passwordUpdatedAt: new Date().toISOString()
        };
        ajax.put(url, {
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: data
        }).then(() => {
          notifications.success('Password updated successfully');
        }).catch(e => {
          //eslint-disable-next-line no-console
          console.log('Error in password update:', e);
          notifications.warning('Unable to update password contact administrator');
        });
      },

      async deleteSchedule() {
        this.set('disableDeleteBtn', true);

        try {
          const notifications = this.get('notifications');
          const schedules = await this.store.findAll('schedule', {
            reload: true
          });

          if (schedules.length === 0) {
            notifications.info('No schedules found to delete.');
            return;
          }

          await Promise.all(schedules.map(schedule => schedule.destroyRecord()));
          await this.resetCurrentSchedule();
          this.notifications.success('All schedules have been deleted successfully.');
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.error('Error deleting schedules:', error);
          this.notifications.warning('Failed to delete schedules. Please try again.');
        } finally {
          this.set('disableDeleteBtn', false);
        }
      },

      onInputChange(password) {
        this.set('userDetails.password', password);
      }

    }
  });

  _exports.default = _default;
});