define("adept-iq/authenticators/sso", ["exports", "ember-simple-auth/authenticators/base", "fetch", "adept-iq/config/api-urls", "adept-iq/config/mapped-permIds"], function (_exports, _base, _fetch, _apiUrls, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    workspace: Ember.inject.service(),

    authenticate(userName, password, tokenPayload) {
      // Bypass login when token payload from /signup during registration passed.
      if (Ember.isPresent(tokenPayload)) {
        return Ember.RSVP.Promise.resolve(tokenPayload);
      }

      const tenantId = window.TENANTS_CONFIG.tenantId;
      const {
        host
      } = _apiUrls.API.ssoService;
      const url = host + '/auth-token';
      const data = {
        'plugin': 'internal;basic',
        'internal': {
          'email': userName,
          'password': password
        },
        'basic': {
          'username': userName,
          'password': password
        }
      }; // Only add tenantId if it is not null

      if (tenantId) {
        data.internal.tenantId = tenantId;
        data.basic.tenantId = tenantId;
      }

      const options = {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(data)
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        (0, _fetch.default)(url, options).then(response => {
          response.json().then(body => {
            if (!response.ok) {
              console.log('User Authentication failed.'); //eslint-disable-line no-console

              reject(body);
            } else {
              const tokenParts = body.token.split('.');
              const tokenInfo = JSON.parse(atob(tokenParts[1]));
              const tenantInfo = tokenInfo.tenant; // attach expiration time for JWT to session data

              body.authMethod = 'sso';
              body.exp = tokenInfo.exp;
              body.userId = tokenInfo.userId;
              body.userSiteId = tenantInfo.siteId;
              body.userTimezone = tenantInfo.timezone;
              body.userTenantDisplayName = tenantInfo.displayName;
              body.userTenantName = tenantInfo.name;
              body.permissions = tokenInfo.permissions;
              body.tokenInfo = tokenInfo;
              const accessWorkspacesPermIdsHash = Object.entries(_mappedPermIds.default).reduce((hash, value) => {
                if (/access.*Workspace/i.test(value[0])) {
                  hash[value[1]] = true;
                }

                return hash;
              }, {});

              if (tokenInfo.permissions.some(p => accessWorkspacesPermIdsHash[p])) {
                resolve(body);
                return;
              }

              reject({
                message: 'MissingWorkspacePermission'
              });
            }
          });
        }).catch(reject);
      });
    },

    restore(data) {
      const notAllowedToProceed = localStorage.getItem('notAllowedToProceed');
      const notAllowedToProceedTarget = localStorage.getItem('notAllowedToProceedTarget');

      if (notAllowedToProceed && notAllowedToProceedTarget) {
        return this.transitionTo(notAllowedToProceedTarget);
      }

      const currentTime = Date.now() / 1000; // expired JWT invalidate session

      if (currentTime >= data.exp) {
        console.log('Token has expired.'); //eslint-disable-line no-console

        return Ember.RSVP.Promise.reject(data);
      }

      return Ember.RSVP.Promise.resolve(data);
    }

  });

  _exports.default = _default;
});