define("adept-iq/classes/active-contexts/schedule/map-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ssTripStopNode = _exports.ssRouteNode = _exports.mapContextNodes = _exports.mapContextModels = _exports.mapContextGraph = _exports.default = void 0;
  // Scheduling Related Nodes
  const ssRouteNode = {
    id: 'ss-route',
    modelName: 'route',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip-stop',
      path: 'tripStops'
    }],
    isActive: true,
    providerFilterKey: false
  };
  _exports.ssRouteNode = ssRouteNode;
  const ssTripStopNode = {
    id: 'ss-trip-stop',
    modelName: 'trip-stop',
    links: [],
    isActive: true,
    providerFilterKey: false
  };
  _exports.ssTripStopNode = ssTripStopNode;
  const mapContextNodes = [// scheduling graph
  ssRouteNode, ssTripStopNode];
  _exports.mapContextNodes = mapContextNodes;
  const mapContextGraph = mapContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.mapContextGraph = mapContextGraph;
  const mapContextModels = mapContextNodes.reduce((obj, node) => {
    obj[node.modelName] = node;
    return obj;
  }, {});
  _exports.mapContextModels = mapContextModels;
  var _default = mapContextGraph;
  _exports.default = _default;
});