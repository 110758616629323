define("adept-iq/models/trip-stop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  const STOP_TYPE_MAP = {
    'pickup': 'pick',
    'delivery': 'drop',
    'break': 'pick',
    'Pullin garage': 'pullinGarage',
    'Pullout garage': 'pulloutGarage'
  };

  var _default = Model.extend({
    stopId: Ember.computed.alias('id'),
    routeId: attr('string'),
    routeShiftIndex: attr('string'),
    vehicleName: attr('string'),
    routeName: attr('string'),
    JobId: attr('string'),
    plannedEta: attr('date'),
    stopType: attr('string'),
    plannedTravelTimeToNext: attr('number'),
    // in minutes
    plannedDistanceToNext: attr('number'),
    // in miles
    plannedSlackTime: attr('number'),
    plannedRouteOrdinal: attr('number'),
    lat: attr('number'),
    lng: attr('number'),
    computedStopType: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');
      return STOP_TYPE_MAP[stopType] || stopType;
    }),
    mapId: Ember.computed.alias('computedStopType'),
    route: belongsTo('route')
  });

  _exports.default = _default;
});