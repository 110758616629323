define("adept-iq/classes/active-contexts/schedule/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ssTripStopNode = _exports.ssRouteNode = _exports.default = _exports.activeContextNodes = _exports.activeContextGraph = void 0;
  // Scheduling Related Nodes
  const ssRouteNode = {
    id: 'ss-route',
    modelName: 'route',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: false
  };
  _exports.ssRouteNode = ssRouteNode;
  const ssTripStopNode = {
    id: 'ss-trip-stop',
    modelName: 'trip-stop',
    links: [{
      type: 'belongsTo',
      nodeId: 'ss-route',
      path: 'route'
    }],
    isActive: true,
    providerFilterKey: false
  };
  _exports.ssTripStopNode = ssTripStopNode;
  const activeContextNodes = [// scheduling graph
  ssRouteNode, ssTripStopNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});