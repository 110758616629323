define("adept-iq/adapters/route", ["exports", "adept-iq/adapters/-raasService", "adept-iq/config/api-urls", "lodash"], function (_exports, _raasService, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    raasServiceHost: _apiUrls.API.raasService.host,

    urlForQuery(query, modelName) {
      const raasServiceHost = this.get('raasServiceHost');
      const baseUrl = `${raasServiceHost}/${modelName}`;

      if (query.scheduleId) {
        const problemId = query.scheduleId;
        delete query.scheduleId; // Append the RQL filter to the URL

        return `${raasServiceHost}/problem/${problemId}/${modelName}`;
      }

      return baseUrl;
    },

    urlForFindRecord(id, modelName
    /*snapshot*/
    ) {
      const raasServiceHost = this.get('raasServiceHost');
      const problemMatch = id.match(/P([\d.]+)/);
      const routeMatch = id.match(/R(\d+(\.\d+)?)S/);

      const problemId = _lodash.default.toNumber(_lodash.default.get(problemMatch, '[1]', null));

      const routeId = _lodash.default.toNumber(_lodash.default.get(routeMatch, '[1]', null));

      const baseUrl = `${raasServiceHost}/problem/${problemId}/${modelName}/${routeId}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});