define("adept-iq/pods/components/sandbox-setup/step2/component", ["exports", "adept-iq/config/icon-paths", "lodash", "ember-concurrency", "adept-iq/mixins/async-schedule-operation", "adept-iq/config/api-urls"], function (_exports, _iconPaths, _lodash, _emberConcurrency, _asyncScheduleOperation, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const raasHost = _apiUrls.API.raasService.host;
  const TIMEOUT_DELAY = 1000; // 1 seconds tries

  const RETRY_LIMIT = 3; // Maximum number of retries

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    widget: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    selectedOption: '',
    availableStrategies: null,
    isOptimizeDisabled: false,
    jsonFile: null,
    timeout: TIMEOUT_DELAY,

    init() {
      this._super(...arguments);

      this.loadOptimizationStrategies();
      this.defaultGif = '/optimize-animations/first_map_point.gif';
      this.stateToGifs = {
        'accepted': ['/optimize-animations/first_line_segment.gif'],
        'queued': ['/optimize-animations/second_line_segment.gif'],
        'in-progress': ['/optimize-animations/second_line_segment.gif'],
        'success': ['/optimize-animations/fifth_map_point.gif']
      };
    },

    async loadOptimizationStrategies() {
      try {
        let strategies = await this.store.findAll('engine-strategy');
        strategies = strategies.filter(strategy => strategy.id !== 'default');
        strategies = (0, _lodash.orderBy)(strategies, ['id'], ['desc']);
        this.set('availableStrategies', strategies);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading strategies:', error);
        this.notifications.error('Failed to load optimization strategies.');
      }
    },

    computedSelectedOption: Ember.computed('selectedOption', 'jsonFile', function () {
      const jsonFile = this.get('jsonFile');
      const problem = jsonFile ? jsonFile.problem : null;
      const objective = problem ? problem.objective : null;
      const availableStrategies = this.get('availableStrategies');
      const strategy = availableStrategies ? this.get('availableStrategies')[objective - 1] : null;
      return strategy ? strategy.id : this.get('selectedOption');
    }),
    onScheduleGenerationSuccessOrFailure: Ember.observer('workspace.scheduleReoptimizeStatus', 'workspace.reoptimizeInProgress', function () {
      const notifications = this.get('notifications'); // Reoptimization is in progress

      if (this.workspace.reoptimizeInProgress) {
        return;
      } // Schedule Generation Processed , enable the optimized btn


      if (this.workspace.scheduleReoptimizeStatus === 'success' || this.workspace.scheduleReoptimizeStatus === 'fail') this.set('isOptimizeDisabled', false);

      if (this.workspace.scheduleReoptimizeStatus === 'success') {
        const router = this.get('router');
        const dashboardId = 'workspaces-schedule/schedule';
        this.store.findRecord('dashboard', dashboardId).then(dashboard => {
          router.transitionTo('dashboard', dashboard.role, dashboard.id);
        }).catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error finding dashboard:', error);
          notifications.warning('Scheduled dashboard not found');
        });
      }

      if (this.workspace.scheduleReoptimizeStatus === 'fail') {
        notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      document.addEventListener('click', this.addBoundingBox.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      document.removeEventListener('click', this.addBoundingBox.bind(this));
    },

    addBoundingBox(event) {
      if (!this.element) {
        return;
      }

      let containersToHighlight = []; // Get all radio buttons and the optimize button

      const radioButtons = Array.from(this.element.querySelectorAll('.radio-button-input input[type="radio"]'));
      const optimizeButtonContainer = this.element.querySelector('.optimize-btn-container');
      const clickedRadioButtonContainer = event.target.closest('.radio-button-input');
      const clickedOptimizeButton = event.target.closest('.optimize-btn');

      if (!this.selectedOption) {
        containersToHighlight = radioButtons;
      } else if (optimizeButtonContainer) {
        containersToHighlight.push(optimizeButtonContainer);
      } // Prevent bounding box if the clicked element is the radio-button container or optimize button already highlighted


      if (clickedRadioButtonContainer && containersToHighlight === radioButtons || clickedOptimizeButton && containersToHighlight.includes(optimizeButtonContainer)) {
        return;
      } // Add the bounding box to the relevant containers


      containersToHighlight.forEach(container => {
        container.classList.add('bounding-box');
      });
      setTimeout(() => {
        containersToHighlight.forEach(container => {
          container.classList.remove('bounding-box');
        });
      }, 400);
    },

    get iconPaths() {
      return {
        sandbox: _iconPaths.default.actionMenu.sandbox,
        upload: _iconPaths.default.actionMenu.upload,
        download: _iconPaths.default.actionMenu.download,
        checkmark: _iconPaths.default.actionMenu.checkmark
      };
    },

    asyncOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');
      const workspace = this.get('workspace');
      let retryCount = 0; // Ensure job has an id before proceeding

      if (!job || !job.id) {
        throw new Error("Invalid job object: missing 'id' field");
      } // Variable for polling response


      let pollingJob;

      do {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay);

        try {
          pollingJob = yield this.get('ajax').request(raasHost + '/raas-async-operation/' + job.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });

          if (this.get('lastProgressState') !== pollingJob.state) {
            const currentState = pollingJob.state;
            const gifs = this.stateToGifs[currentState] || [];
            workspace.set('spinnerImageUpdate', gifs.join(', '));
            this.set('lastProgressState', pollingJob.state);
          }
        } catch (e) {
          retryCount += 1; // Retry in case of Timeout from API

          if (retryCount > this.get('retryLimit')) {
            throw new Error(`Failed after ${RETRY_LIMIT} attempts: ${e.message}`);
          }
        }
      } while (pollingJob.state !== 'success' && pollingJob.state !== 'failure' && pollingJob.state !== 'rejected'); //Job accepted but not completed


      const isJobSuccess = pollingJob.state === 'success';
      const jsonData = pollingJob || {};

      if (isJobSuccess) {
        const runTime = jsonData.runTime;
        workspace.set('spinnerTextUpdate', `${Math.round(runTime)}s`);
      }

      return {
        jsonData,
        isJobSuccess
      };
    }).drop(),
    actions: {
      async onOptimizeBtnClick() {
        const notifications = this.get('notifications');
        const workspace = this.get('workspace');

        try {
          workspace.set('spinnerImageUpdate', this.defaultGif);
          workspace.set('isGlobalSpinnerVisible', true);
          this.set('isOptimizeDisabled', true);
          const problem = this.get('jsonFile');
          const result = await this.createScheduleAsyncOperation.perform(problem);
          const job = await this.get('asyncOperationState').perform(result.job);

          if (job.isJobSuccess) {
            const scheduleId = result.job.problemId;
            const schedule = await this.get('store').findRecord('schedule', scheduleId);
            workspace.set('currentSchedule', schedule); // this.workspace.set('selectedDate', date);

            const router = this.get('router');
            const dashboardId = 'workspaces-schedule/schedule'; // Delay for animation

            await new Promise(resolve => setTimeout(resolve, 5000));
            workspace.set('isGlobalSpinnerVisible', false);
            workspace.set('spinnerImageUpdate');
            workspace.set('spinnerTextUpdate');
            this.store.findRecord('dashboard', dashboardId).then(dashboard => {
              router.transitionTo('dashboard', dashboard.role, dashboard.id);
            }).catch(error => {
              // eslint-disable-next-line no-console
              console.error('Error finding dashboard:', error);
              notifications.warning('Scheduled dashboard not found');
            });
          } else {
            this.set('isOptimizeDisabled', false);
            workspace.set('isGlobalSpinnerVisible', false);
            workspace.set('spinnerImageUpdate');
            workspace.set('spinnerTextUpdate');
            notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
          }
        } catch (error) {
          workspace.set('isGlobalSpinnerVisible', false);
          workspace.set('spinnerImageUpdate');
          workspace.set('spinnerTextUpdate'); // eslint-disable-next-line no-console

          console.error('Error during optimize operation:', error);
          notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
        }
      },

      updateOption(selectedOption) {
        // Handle the option change
        this.set('selectedOption', selectedOption);
        const jsonFile = this.get('jsonFile');
        const problem = jsonFile ? jsonFile.problem : null;
        const availableStrategies = this.get('availableStrategies');
        const index = availableStrategies.findIndex(strategy => {
          return strategy.id === selectedOption;
        });

        if (problem) {
          problem.objective = index + 1;
        }
      }

    }
  });

  _exports.default = _default;
});