define("adept-iq/authenticators/sso-external", ["exports", "ember-simple-auth/authenticators/base", "fetch", "adept-iq/config/api-urls", "adept-iq/config/mapped-permIds"], function (_exports, _base, _fetch, _apiUrls, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    workspace: Ember.inject.service(),

    authenticate(tokenPayload) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const tokenParts = tokenPayload.split('.');
        const tokenInfo = JSON.parse(atob(tokenParts[1]));
        const userId = tokenInfo.userId;

        if (!userId) {
          reject('Invalid SSO token.');
        }

        const url = `${_apiUrls.API.ssoService.host}/user/${userId}`;
        (0, _fetch.default)(url, {
          contentType: 'application/json',
          dataType: 'text',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${tokenPayload}`
          }
        }).then(response => {
          if (!response.ok) {
            reject('Invalid SSO token.');
          }

          const tenantInfo = tokenInfo.tenant;
          const body = {
            authMethod: 'sso-external',
            token: tokenPayload,
            exp: tokenInfo.exp,
            userId: tokenInfo.userId,
            userSiteId: tenantInfo.siteId,
            userTimezone: tenantInfo.timezone,
            userTenantDisplayName: tenantInfo.displayName,
            userTenantName: tenantInfo.name,
            permissions: tokenInfo.permissions,
            tokenInfo
          };
          const accessWorkspacesPermIdsHash = Object.entries(_mappedPermIds.default).reduce((hash, value) => {
            if (/access.*Workspace/i.test(value[0])) {
              hash[value[1]] = true;
            }

            return hash;
          }, {});

          if (tokenInfo.permissions.some(p => accessWorkspacesPermIdsHash[p])) {
            resolve(body);
            return;
          }

          reject({
            message: 'MissingWorkspacePermission'
          });
        }).catch(reject);
      });
    },

    restore(data) {
      const notAllowedToProceed = localStorage.getItem('notAllowedToProceed');
      const notAllowedToProceedTarget = localStorage.getItem('notAllowedToProceedTarget');

      if (notAllowedToProceed && notAllowedToProceedTarget) {
        return this.transitionTo(notAllowedToProceedTarget);
      }

      const currentTime = Date.now() / 1000; // expired JWT invalidate session

      if (currentTime >= data.exp) {
        console.log('Token has expired.'); //eslint-disable-line no-console

        return Ember.RSVP.Promise.reject(data);
      }

      return Ember.RSVP.Promise.resolve(data);
    }

  });

  _exports.default = _default;
});