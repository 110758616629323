define("adept-iq/classes/polylines/routes/scheduled/iq", ["exports", "adept-iq/classes/polylines/routes/scheduled/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    isActive: true,
    isHidden: false,
    points: Ember.computed('iqRoute.scheduledPolyline.coords', function () {
      const iqRoute = this.get('iqRoute');

      if (iqRoute && iqRoute.get('scheduledPolyline')) {
        return iqRoute.get('scheduledPolyline').get('coords') || [];
      }

      return [];
    }),
    inProgress: false,
    // local properties
    polylineService: null,
    record: null,
    iqRoute: Ember.computed.alias('record'),

    init() {
      this._super(...arguments);

      this.visibilityListener();
    },

    visibilityListener: Ember.observer('iqRoute.isPerformed', 'isHidden', 'isJoined', function () {
      if (Ember.isPresent(this.get('points')) || this.get('inProgress')) return;
      const route = this.get('iqRoute'); //Draw Route-Polyline only if route is present

      if (!Ember.isPresent(route)) {
        return;
      }

      this.set('inProgress', true);
      this.get('polylineService.fetchScheduledPolyline').perform(route).then(() => {
        this.set('inProgress', false);
      }).catch(() => {
        this.set('inProgress', false);
      });
    }),
    bemapCoords: Ember.computed('points', function () {
      const points = this.get('points') || [];
      return points.map(point => {
        return {
          lat: point[0],
          lon: point[1]
        };
      });
    })
  });

  _exports.default = _default;
});