define("adept-iq/services/widget", ["exports", "adept-iq/config/api-urls", "adept-iq/config/environment", "adept-iq/classes/work-queue", "ember-concurrency", "lodash", "moment", "adept-iq/config/widget-events"], function (_exports, _apiUrls, _environment, _workQueue, _emberConcurrency, _lodash, _moment, _widgetEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orRQL = _exports.neRQL = _exports.inRQL = _exports.eqRQL = _exports.default = _exports.andRQL = void 0;
  // const QUEUED_THRESHOLD = 200;
  const MAX_VEHICLE_LIMIT = 3000;
  const MAX_DRIVER_LIMIT = 3000;
  const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const andRQL = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return `and(${args.join(',')})`;
  };

  _exports.andRQL = andRQL;

  const inRQL = (p, values) => {
    return `in(${p},(${values.join(',')}))`;
  };

  _exports.inRQL = inRQL;

  const orRQL = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return `or(${args.join(',')})`;
  };

  _exports.orRQL = orRQL;

  const eqRQL = (p, v) => `eq(${p},${v})`;

  _exports.eqRQL = eqRQL;

  const neRQL = (p, v) => `ne(${p},${v})`;

  _exports.neRQL = neRQL;
  const CORE_WIDGET_MAPPING = {
    'route': 'routeWidget',
    'trip': 'tripWidget',
    'trip-stop': 'stopWidget',
    'rider': 'riderWidget',
    'driver': 'driverWidget',
    'alert': 'alertWidget',
    'avlm-canned-message': 'messageWidget'
  };
  const MODEL_API_MAPPING = {
    'route': 'route',
    'trip': 'trip',
    'trip-stop': 'trip',
    'rider': 'riderWidget',
    'driver': 'driverWidget',
    'alert': 'alertWidget',
    'avlm-canned-message': 'messageWidget'
  };
  const AVLM_CORE_WIDGET_MAPPING = {
    'avlm-route': 'routeWidget',
    'avlm-trip': 'tripWidget',
    'avlm-stop-point': 'stopWidget',
    'avlm-rider': 'riderWidget',
    'avlm-driver': 'driverWidget',
    'avlm-alert': 'alertWidget',
    'avlm-canned-message': 'messageWidget'
  };
  const WIDGET_MODEL_MAP = {
    vehicleWidget: 'vehicle',
    stopWidget: 'trip-stop',
    routeWidget: 'dispatch-route',
    tripWidget: 'trip',
    alertWidget: 'avlm-alert',
    messageWidget: 'avlm-canned-message',
    driverWidget: 'driver'
  };
  const AVLM_WIDGET_MODEL_MAP = {
    vehicleWidget: 'avlm-vehicle',
    stopWidget: 'avlm-stop-point',
    routeWidget: 'avlm-route',
    tripWidget: 'avlm-trip',
    alertWidget: 'avlm-alert',
    messageWidget: 'avlm-canned-message',
    driverWidget: 'avlm-driver',
    riderWidget: 'avlm-rider'
  };
  const GLOBAL_WIDGET_INST = 'STORE_SELECTION';
  const {
    readOnly
  } = Ember.computed; // you must register any model that is serialized to a config item here

  const modelNameForCategory = {
    'config-Scheduling-schedule_configurations': 'schedule-configuration',
    'config-Scheduling-service_windows': 'service-window',
    'config-Activity_Log_Types-trip': 'trip-activity-log-type',
    'config-Activity_Log_Types-vehicle': 'vehicle-activity-log-type',
    'config-Activity_Log_Types-route': 'route-activity-log-type',
    'config-Activity_Log_Types-user': 'user-activity-log-type',
    'config-System_Configuration-break_types': 'break-type',
    'config-System_Configuration-cancel_types': 'cancel-type',
    'config-System_Configuration-eligibility_types': 'eligibility-type',
    'config-System_Configuration-eligibility_categories': 'eligibility-category',
    'config-System_Configuration-fare_types': 'fare-type',
    'config-System_Configuration-no_show_reason_codes': 'no-show-reason-code',
    'config-System_Configuration-passenger_types': 'passenger-type',
    'config-System_Configuration-place_category_types': 'place-category-type',
    'config-System_Configuration-providers': 'provider',
    'config-System_Configuration-travel_need_types': 'travel-need-type',
    'config-System_Configuration-vehicle_capacity_configs': 'vehicle-capacity-config',
    'config-System_Configuration-vehicle_capacity_types': 'vehicle-capacity-type',
    'config-System_Configuration-vehicle_types': 'vehicle-type',
    'config-System_Configuration-breakdown_types': 'breakdown-type',
    'config-System_Configuration-zones': 'zone-type',
    'config-System_Configuration-rider_place_types': 'rider-place-type',
    'config-System_Configuration-no_show_reason_categories': 'no-sho-reason-category',
    'config-System_Configuration-Activity_Log_Types-booking': 'booking-activity-log-type',
    'config-System_Configuration-Activity_Log_Types-subscription': 'subscription-activity-log-type',
    'config-System_Configuration-fare_categories': 'fare-category',
    'config-Engine_Strategy': 'engine-strategy'
  };
  const DATE_FORMAT = 'YYYY-MM-DD';
  const TOMORROW = (0, _moment.default)().add(1, 'day').format(DATE_FORMAT);
  const TODAY = (0, _moment.default)().format(DATE_FORMAT);

  var _default = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    socket: Ember.inject.service(),
    user: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    work: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    fixedRouteEngine: Ember.inject.service(),
    storeQueue: Ember.computed.alias('workspaceContext.storeQueue'),
    normalizeQueue: Ember.computed.alias('workspaceContext.normalizeQueue'),
    getCoreModelNameByWidgetRecord: Ember.computed.alias('activeContext.topActiveContext.getCoreModelNameByWidgetRecord'),
    configData: null,
    isConfigLoaded: false,
    isDataLoaded: false,
    readyToLoadInitialData: false,
    traversalFilters: null,
    widgetPayLoadBatch: null,
    coreEntityPayLoadBatch: null,
    traversalFilterQueryDict: null,
    startDate: null,
    endDate: null,
    providersList: Ember.computed.alias('workspaceContext._selectedProviders'),
    allProviders: Ember.computed.alias('workspaceContext._allProviders'),
    widgetEntityQueue: null,
    avlEntityQueue: null,
    isScheduleDashboard: readOnly('workspace.isScheduleDashboard'),
    isDispatchDashboard: readOnly('workspace.isDispatchDashboard'),
    futureBookings: null,
    futureSubscriptions: null,
    clusterEntityQueue: null,
    widgetModelMap: WIDGET_MODEL_MAP,

    setWidgetModelMap(widgetModel, coreModel) {
      const defaultModelMap = _environment.default.APP.avlmLite ? AVLM_WIDGET_MODEL_MAP : WIDGET_MODEL_MAP;
      const widgetModelMap = this.get('widgetModelMap') || defaultModelMap;

      if (widgetModel && coreModel) {
        widgetModelMap[widgetModel] = coreModel;
      }

      this.set('widgetModelMap', widgetModelMap);
    },

    init() {
      this._super(...arguments);

      this.widgetPayLoadBatch = [];
      this.coreEntityPayLoadBatch = [];
      this.set('traversalFilters', {});
      this.set('traversalFilterQueryDict', {});
      this.set('futureBookings', []);
      this.set('futureSubscriptions', []);
      this.loadConfig().then(data => {
        this.set('configData', data);
        this.set('isConfigLoaded', true);
      });
      const widgetEntityQueueOptions = Object.assign({}, _environment.default.work['widget-entity'], {
        name: 'widget-entity',
        perform: jobs => {
          jobs.forEach(job => {
            this._processWidgetEntity(job);
          });
        }
      });

      const widgetEntityQueue = _workQueue.default.extend({
        isDisabled: false,
        groupBy: 'payloadBatch'
      }).create(widgetEntityQueueOptions);

      this.get('work').registerQueue(widgetEntityQueue);
      this.set('widgetEntityQueue', widgetEntityQueue);
      const avlEntityQueueOptions = Object.assign({}, _environment.default.work['avl-entity'], {
        name: 'avl-entity',
        perform: jobs => {
          jobs.forEach(job => {
            this._processAvlEntity(job);
          });
        }
      });

      const avlEntityQueue = _workQueue.default.extend({
        isDisabled: false
      }).create(avlEntityQueueOptions);

      this.get('work').registerQueue(avlEntityQueue);
      this.set('avlEntityQueue', avlEntityQueue); //this.getFixedRouteEngineParams();

      this.setWidgetModelMap(); //this.getFutureBookings();
      //this.getFutureSubscriptions();

      const clusterEntityQueueOptions = Object.assign({}, _environment.default.work['cluster-entity'], {
        name: 'cluster-entity',
        perform: jobs => {
          jobs.forEach(job => {
            this._processClusterEntity(job);
          });
        }
      });

      const clusterEntityQueue = _workQueue.default.extend({
        isDisabled: false
      }).create(clusterEntityQueueOptions);

      this.get('work').registerQueue(clusterEntityQueue);
      this.set('clusterEntityQueue', clusterEntityQueue);
      this.onActiveContextChanged();
    },

    unloadAllRecords(modelName) {
      this.store.peekAll(modelName).forEach(record => record.unloadRecord());
    },

    async loadVehicle() {
      this.unloadAllRecords('vehicle');
      return this.get('store').findAll('vehicle');
    },

    async loadRoutes() {
      const dateQueryFields = this.getDateQueryFields();
      const scheduleId = dateQueryFields.scheduleId;

      if (!scheduleId) {
        return;
      }

      return this.get('store').query('route', {
        scheduleId: scheduleId,
        reload: true
      });
    },

    async loadConfig() {
      const session = this.get('session');
      const configList = await this.get('ajax').request(`${_apiUrls.API.configService.host}/config/config`, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).catch(() => []);
      const separatedData = Ember.makeArray(configList.data).reduce((obj, datum) => {
        const [categoryId] = datum.id.split('/');
        const modelName = modelNameForCategory[categoryId] || 'cs-config-item';

        if (modelName) {
          obj[modelName] = obj[modelName] || [];
          obj[modelName].push(datum);
        }

        return obj;
      }, {});
      await this.get('workspaceContext').setupProviderList();
      return separatedData;
    },

    onActiveContextChanged: Ember.observer('activeContext.topActiveContext', function () {
      const context = this.get('activeContext.topActiveContext');

      if (context) {
        this.set('traversalFilters', {});
        this.set('traversalFilterQueryDict', {});
      }
    }),
    onReadyToLoad: Ember.observer('isConfigLoaded', 'workspace.{isScheduleDashboard,currentSchedule,triggeredReoptimize}', function () {
      if (this.get('workspace.isScheduleDashboard') && this.get('isConfigLoaded') && !this.get('workspace.triggeredReoptimize')) {
        if (!this.get('isDataLoaded')) {
          Promise.all([this.loadVehicle()]).then(() => {
            this.set('isDataLoaded', true);
            this.set('readyToLoadInitialData', true);
            this.trigger(_widgetEvents.INITIAL_WIDGET_LOAD_EVENT);
          }).catch(error => {
            // eslint-disable-next-line no-console
            console.error('Failed to load vehicle data:', error);
          });
        } else {
          this.set('readyToLoadInitialData', true);
          this.trigger(_widgetEvents.INITIAL_WIDGET_LOAD_EVENT);
        }
      }
    }),
    loadAllVehicle: (0, _emberConcurrency.task)(function* () {
      const modelName = 'vehicleWidget';
      const limit = MAX_VEHICLE_LIMIT;
      const sort = 'id';
      const filterString = '';
      const dateQueryFields = this.getDateQueryFields();
      const selectedProviders = this.getSelectedProviders();
      let offset = 0;
      let records = [];

      do {
        records = yield this.loadData(GLOBAL_WIDGET_INST, modelName, filterString, offset, limit, sort, dateQueryFields, selectedProviders);

        if (records.length > 0) {
          this.updateAllCoreEntityStoreAction(modelName, records);
          offset += records.length;
        }
      } while (records.length > 0);
    }),
    loadAllDriver: (0, _emberConcurrency.task)(function* () {
      const modelName = 'driverWidget';
      const limit = MAX_DRIVER_LIMIT;
      const sort = 'id';
      const filterString = '';
      const dateQueryFields = this.getDateQueryFields();
      const selectedProviders = this.getSelectedProviders();
      let offset = 0;
      let records = [];

      do {
        records = yield this.loadData(GLOBAL_WIDGET_INST, modelName, filterString, offset, limit, sort, dateQueryFields, selectedProviders);

        if (records.length > 0) {
          yield this.loadDriverAvailabilities(records);
          this.updateAllCoreEntityStoreAction(modelName, records);
          offset += records.length;
        }
      } while (records.length > 0);
    }),
    // minimize will create a new dashboard instance so trigger startDate/endDate update, but that will reset all widgets.
    dateRangeFilterQuery: Ember.observer('workspace.{startDate,endDate}', function () {
      const topActiveContext = this.get('activeContext.topActiveContext');
      const activeContextNodes = topActiveContext.get('nodes') || [];
      this.globalFilterChanged();
      topActiveContext.clearCheckedItems();
      activeContextNodes.reduce((obj, node) => {
        this.get('workspaceContext').trigger('change', [node.modelName]);
      });
    }),

    // providerObserver: observer('workspaceContext.providerUpdated', function() {
    //   this.globalFilterChanged();
    // }),
    setTraversalFilterQuery(modelName, queryString) {
      this.set('traversalFilterQueryDict', {
        [modelName]: queryString
      });
    },

    getTraversalFilterQuery(modelname) {
      return this.get('traversalFilterQueryDict')[modelname] || '';
    },

    globalFilterChanged() {
      const readyToLoadInitialData = this.get('readyToLoadInitialData');

      if (readyToLoadInitialData) {
        const dateQueryFields = this.getDateQueryFields();
        const selectedProviders = this.getSelectedProviders();
        this.updateGlobalFilter(dateQueryFields, selectedProviders);
        this.get('widgetEntityQueue').clearJobs();
      }
    },

    getSelectedProvidersDict() {
      const selectedProviders = this.getSelectedProviders();
      const selectedProvidersDict = selectedProviders.reduce((d, x) => ({ ...d,
        [x]: true
      }), {});
      return selectedProvidersDict;
    },

    getSelectedProviders() {
      const selectedProviders = this.get('providersList') || [];
      const selectedProviderNames = selectedProviders.map(provider => provider.id);
      return selectedProviderNames;
    },

    getAllProviders() {
      const selectedProviders = this.get('store').peekAll('provider');
      const selectedProviderNames = selectedProviders.map(provider => provider.id);
      selectedProviderNames.push('UNASSIGNED');
      return selectedProviderNames;
    },

    /**
     * data received from socket will reach this place
     * @param widgetData : {action , widgetInstanceId, widgetModelName, widgetEnitity}
     */
    receiveWidgetUpdate(payload) {
      // DELETE action won't have provider in body.
      if (this.get('readyToLoadInitialData')) {
        // DELETE action won't have provider in body.
        if (payload.header.action === 'DELETE' || this.matchesProvider(payload)) {
          if (payload.body && payload.body.id) {
            payload.body.data = payload.body.data || {};
            payload.body.data.id = payload.body.id;
            payload.body.data._key = payload.body.id;
          }

          const payLoadModelName = payload.header.widgetModelName;
          const jobId = `${payLoadModelName}`;
          const payloadBatch = Ember.isArray(payload) ? payload : [payload];
          const job = {
            payloadBatch,
            jobId
          };
          this.get('widgetEntityQueue').mergeJob(job);
          this.get('work').start();
        }
      }
    },

    /**
     * Called from Socket Service when we get a CORE_ENTITY_UPDATE event
     * @param payload
     */
    receiveCoreEntityUpdate(payload) {
      if (this.get('readyToLoadInitialData')) {
        if (payload.body && payload.body.data) {
          const {
            data
          } = payload.body;

          if (data.type === 'avl') {
            this.get('avlEntityQueue').addJob({
              data
            });
          }

          if (data.type === 'schedule') {
            const schedule = payload.body;
            this.get('store').push(schedule);
          }

          if (data.type === 'cluster') {
            this.get('clusterEntityQueue').addJob({
              data
            });
          }
        }
      }
    },

    _processWidgetEntity(job) {
      this.preWidgetRecordUpdate(job.payloadBatch);
      this.trigger(_widgetEvents.WIDGET_DATA_ACTION, job.payloadBatch);
      this.postWidgetRecordUpdate(job.payloadBatch);
      this.updateMap();
    },

    _processAvlEntity(payload) {
      const store = this.get('store');
      const {
        data
      } = payload;
      const {
        id
      } = data.attributes;
      let {
        lat,
        lng,
        speed,
        heading,
        odo,
        timestamp
      } = data.attributes;
      lat = Ember.isPresent(lat) ? parseFloat(lat) : null;
      lng = Ember.isPresent(lng) ? parseFloat(lng) : null;
      heading = Ember.isPresent(heading) ? parseFloat(heading) : null;
      speed = Ember.isPresent(speed) ? parseFloat(speed) : null;
      odo = Ember.isPresent(odo) ? parseFloat(odo) : null;
      timestamp = Ember.isPresent(timestamp) ? new Date(timestamp) : null;
      const vehicle = store.peekRecord(_environment.default.APP.avlmLite ? 'avlm-vehicle' : 'vehicle', id);

      if (vehicle) {
        vehicle.set('avlLocation', {
          lat,
          lng,
          heading,
          speed,
          odo,
          timestamp
        });
      }
    },

    _processClusterEntity(payload) {
      const store = this.get('store');
      const {
        data
      } = payload;
      const clusterId = data.id;
      const storeQueue = this.get('storeQueue');
      let dispatchRouteId = null;

      if (!Ember.isNone(data.relationships)) {
        dispatchRouteId = data.relationships.dispatchRoute ? data.relationships.dispatchRoute.data.id : null;
      }

      const cluster = store.peekRecord('cluster', clusterId); // If dispatchRoute changed then remove the relationship between cluster and dispatchRoute.

      if (cluster && (!dispatchRouteId || cluster.get('dispatchRoute.id') !== dispatchRouteId)) {
        cluster.set('dispatchRoute', null);
        cluster.saveWithoutSubmit();
      } else {
        storeQueue.pushJob({
          action: 'push',
          payload
        });
      }
    },

    updateMap() {// throttle(this, this._internalUpdateMap, MAP_UPDATE_DEBOUNCE_INTERVAL);
    },

    _internalUpdateMap() {
      this.get('workspaceContext').refreshMap();
    },

    /**
     *
     * Take any action  such as add/remove/update from store or update any other
     * data held by other services
     */
    // eslint-disable-next-line no-unused-vars
    preWidgetRecordUpdate(widgetRecordPayloads) {},

    /**
     * Called After widget records are updated on the grid
     * Take any action  such as add/remove/update from store or update
     * any other data held by other services
     */
    // Will come back to this function later to handle the multiple model's request
    postWidgetRecordUpdate(widgetRecordPayloads) {
      widgetRecordPayloads.forEach(widgetRecordPayload => {
        const {
          header,
          body
        } = widgetRecordPayload;
        const {
          action,
          widgetModelName
        } = header;
        const {
          data
        } = body;
        let model = this.get('widgetModelMap')[widgetModelName]; //  there is a difference in backend model name

        const modelName = Ember.String.camelize(widgetModelName);

        if (typeof this.get('getCoreModelNameByWidgetRecord') === 'function') {
          model = this.get('getCoreModelNameByWidgetRecord')(model, widgetRecordPayload);
        } // Test if UI has widget opened in dashboard


        if (model && this.canUpdateStore(action, modelName)) {
          // avoid core entity updates also updates widgetRecords, so we need to clone to store.
          this.storeWidgetRecord(model, data);
        }
      });
    },

    /**
     * There is a delay between computing the selected provider list and WebSocket Global Filter setting
     * This is to dicard widget record that doesnt match the current UI filter
     * @param widgetRecordPayload
     * @returns {boolean}
     */
    matchesProvider(widgetRecordPayload) {
      const {
        body
      } = widgetRecordPayload;

      if (body.data && body.data.provider) {
        const providersDict = this.getSelectedProvidersDict();
        return providersDict[body.data.provider];
      }

      return false;
    },

    canUpdateStore(action, modelName) {
      if (modelName === 'messageWidget') {
        return true;
      }

      if (modelName === 'alertWidget') {
        return true;
      }

      if (action === 'UPDATE') {
        return true;
      }

      return false;
    },

    // eslint-disable-next-line no-unused-vars
    preCoreEntityUpdate(payload) {},

    // eslint-disable-next-line no-unused-vars
    postCoreEntityUpdate(payload) {},

    /**
     * if the widget record is in selected state, we need to push its corresponding
     * core entity to Store by extracting fields from the widget Record
     *
     * This happens on two occassions. When user selects and when we receive a widget update action (subject to selected state)
     * @param widgetModelName {string}
     * @param primaryKey
     * @returns {boolean}
     */
    isSelected(widgetModelName, primaryKey) {
      // eslint-disable-next-line guard-for-in
      for (const widgetInstance in this.traversalFilters) {
        const filters = this.traversalFilters[widgetInstance];

        for (const filter of filters) {
          if (filter.model === widgetModelName) {
            if (filter._key === primaryKey) {
              return true;
            }
          }
        }
      }

      return false;
    },

    /**
     * Called when Maximized and Minimized
     * @param targetInstanceId
     * @param sourceInstanceId
     */
    transferTraversalFilterState(targetInstanceId, sourceInstanceId) {
      const filters = this.traversalFilters[sourceInstanceId] || [];
      this.traversalFilters[targetInstanceId] = filters;
      this.traversalFilters[sourceInstanceId] = [];
    },

    async loadData(widgetInstanceId, modelName, filter, offset, limit, sort, dateQueryFields, selectedProviders) {
      const data = await this.requestWidgetApi(widgetInstanceId, modelName, filter, offset, limit, sort, dateQueryFields, selectedProviders);
      this.updateWidgetFilter(widgetInstanceId, modelName, filter, offset, limit, sort);
      this.trigger(_widgetEvents.RECEIVE_DATA_LIST_EVENT, {
        widgetInstanceId,
        modelName,
        data
      });
      return data;
    },

    async loadMoreData(widgetInstanceId, modelName, filter, offset, limit, sort, dateQueryFields, selectedProviders) {
      const data = await this.requestWidgetApi(widgetInstanceId, modelName, filter, offset, limit, sort, dateQueryFields, selectedProviders);
      this.updateWidgetFilter(widgetInstanceId, modelName, filter, offset, limit, sort);
      this.trigger(_widgetEvents.LOAD_MORE_DATA_LIST_EVENT, {
        widgetInstanceId,
        modelName,
        data
      });
      return data;
    },

    // eslint-disable-next-line no-unused-vars
    async requestWidgetApi(widgetInstanceId, modelName, filter, offset, limit, sort, dateQueryFields, selectedProviders) {
      const model = this.get('widgetModelMap')[modelName];
      const apiEndpoint = MODEL_API_MAPPING[model];

      if (!apiEndpoint) {
        throw new Error(`No API endpoint defined for Widget: ${modelName}`);
      }

      const queryTerms = [];
      let records = [];

      if (!dateQueryFields || !dateQueryFields.scheduleId) {
        return records;
      }

      const scheduleIdCond = eqRQL('scheduleId', dateQueryFields.scheduleId);
      let rqlFilter = encodeURIComponent(scheduleIdCond);

      if (!_lodash.default.isEmpty(filter)) {
        rqlFilter = andRQL(rqlFilter, filter);
      }

      queryTerms.push(`filter=${rqlFilter}`);

      if (modelName === 'routeWidget') {
        queryTerms.push('include=trips');
      }

      const queryString = queryTerms.join('&');
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.schedulingService.host}/${apiEndpoint}?${queryString}`;
      const response = await this.get('ajax').request(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json'
      });

      switch (modelName) {
        case 'stopWidget':
          {
            const scheduleId = dateQueryFields.scheduleId;
            const [tripStopData, garageStops] = await Promise.all([this.extractTripStopData(response), this.getAndFormatGarageStops(filter, scheduleId)]);
            records = [...tripStopData, ...garageStops];
            break;
          }

        case 'tripWidget':
          records = this.extractTripData(response);
          break;

        case 'routeWidget':
          records = this.extractRouteData(response.data);
          break;

        default:
          records = this.extractData(response.data);
          break;
      }

      return records;
    },

    extractData(data) {
      return data.map(function (jsonRecord) {
        const record = jsonRecord.attributes;
        record.id = jsonRecord.id;
        record._key = jsonRecord.id; // Flatten relationships into the record, if relationships exist

        if (jsonRecord.relationships) {
          Object.entries(jsonRecord.relationships).forEach(_ref => {
            let [key, value] = _ref;

            if (value && value.data && value.data.id) {
              record[key + 'Id'] = value.data.id;
            }
          });
        }

        return record;
      });
    },

    extractRouteData(data) {
      const vehicles = this.store.peekAll('vehicle');
      const vehicleMap = {};
      vehicles.forEach(vehicle => {
        vehicleMap[vehicle.get('id')] = vehicle.get('name');
      });
      return data.map(function (jsonRecord) {
        const record = jsonRecord.attributes;
        record.id = jsonRecord.id;
        record._key = jsonRecord.id; // Flatten relationships into the record, if relationships exist

        if (jsonRecord.relationships) {
          Object.entries(jsonRecord.relationships).forEach(_ref2 => {
            let [key, value] = _ref2;

            if (value && value.data) {
              if (Array.isArray(value.data)) {
                record[key + 'Ids'] = value.data.map(item => item.id);
              } else if (value.data.id) {
                record[key + 'Id'] = value.data.id;
              }
            }
          });
        }

        const vehicleId = record.vehicleId;

        if (vehicleId && vehicleMap[vehicleId]) {
          record.vehicleName = vehicleMap[vehicleId];
        }

        return record;
      }).filter(record => Array.isArray(record.tripsIds) && record.tripsIds.length > 0);
    },

    applyRQLFilter(records, filter) {
      const decodedFilter = decodeURIComponent(filter);
      const [operation, ...args] = decodedFilter.split(/[(,)]/).map(arg => arg.trim()).filter(arg => arg);

      if (operation === 'eq' && args.length === 2) {
        const [field, value] = args;
        return _lodash.default.filter(records, [field, value]);
      } // If filter is not recognized, return the empty record


      return [];
    },

    getAndFormatGarageStops(filter, scheduleId) {
      const decodedFilter = decodeURIComponent(filter, scheduleId); // Check if a specific routeId filter is provided and extract the routeId

      const routeIdMatch = decodedFilter ? /eq\(routeId,(\d+)\)/.exec(decodedFilter) : null;
      const routePk = routeIdMatch ? routeIdMatch[1] : null;
      let filterApplied = false;
      let vehicles = [];
      let routes = [];

      if (routePk) {
        filterApplied = true;
        const route = this.store.peekRecord('route', routePk);

        if (route) {
          const vehicleId = _lodash.default.get(route, 'vehicleId');

          vehicles = [this.store.peekRecord('vehicle', vehicleId)].filter(Boolean);
          routes = [route];
        }
      } else {
        vehicles = this.store.peekAll('vehicle').toArray();
        routes = this.store.peekAll('route').toArray();
      } // Create a map of vehicleId to route for quick lookup


      const vehicleToRouteMap = _lodash.default.reduce(routes, (acc, route) => {
        const vehicleId = _lodash.default.get(route, 'vehicleId');

        acc[vehicleId] = route;
        return acc;
      }, {});

      const garageStops = vehicles.map(vehicle => {
        const {
          startGarageDisplayName,
          startGarageLat,
          startGarageLng,
          endGarageDisplayName,
          endGarageLat,
          endGarageLng
        } = vehicle.getProperties('name', 'startGarageDisplayName', 'startGarageLat', 'startGarageLng', 'endGarageDisplayName', 'endGarageLat', 'endGarageLng');
        const route = vehicleToRouteMap[vehicle.id];

        const routeName = _lodash.default.get(route, 'name');

        const routeId = _lodash.default.get(route, 'id');

        const routeStartTime = _lodash.default.get(route, 'plannedStartTime');

        const routeEndTime = _lodash.default.get(route, 'plannedEndTime'); // Define Garage Stops: pullin and pullout


        return [{
          id: `GP${vehicle.id}`,
          vehiclePk: vehicle.id,
          type: 'trip-stop',
          address: startGarageDisplayName,
          lat: startGarageLat,
          lng: startGarageLng,
          stopType: 'pulloutGarage',
          plannedRouteOrdinal: 0,
          plannedEta: routeStartTime,
          routeName,
          routeId
        }, {
          id: `GD${vehicle.id}`,
          vehiclePk: vehicle.id,
          type: 'trip-stop',
          address: endGarageDisplayName,
          lat: endGarageLat,
          lng: endGarageLng,
          stopType: 'pullinGarage',
          plannedEta: routeEndTime,
          routeName,
          routeId
        }];
      }).flat(); // Apply filter if not applied

      if (decodedFilter && !filterApplied) {
        return this.applyRQLFilter(garageStops, decodedFilter);
      }

      return garageStops;
    },

    extractTripData(response) {
      const included = _lodash.default.get(response, 'included', []); // Create a lookup table for routes , tripStops and travelNeeds from the included section.


      const routes = {};
      const tripStops = {};
      const tripTravelNeeds = {};
      const serviceWindow = this.store.peekRecord('service-window', 'GENERAL');
      included.forEach(jsonRecord => {
        if (jsonRecord.type === 'route') {
          routes[jsonRecord.id] = jsonRecord;
        } else if (jsonRecord.type === 'tripStop') {
          tripStops[jsonRecord.id] = jsonRecord;
        } else if (jsonRecord.type === 'tripTravelNeed') {
          tripTravelNeeds[jsonRecord.id] = jsonRecord;
        }
      }); // Load all travel-need-type records upfront

      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const travelNeedTypeMap = {};
      travelNeedTypes.forEach(type => {
        travelNeedTypeMap[type.id] = type.get('displayName');
      });
      const tripData = response.data.map(jsonRecord => {
        const record = jsonRecord.attributes;
        record.id = jsonRecord.id;
        record._key = jsonRecord.id; // Flatten relationships into the record, if relationships exist

        if (jsonRecord.relationships) {
          Object.entries(jsonRecord.relationships).forEach(_ref3 => {
            let [key, value] = _ref3;

            if (value && value.data && value.data.id) {
              record[key + 'Id'] = value.data.id;

              if (key === 'route' && routes[value.data.id]) {
                record.routeName = _lodash.default.get(routes[value.data.id], 'attributes.name');
              }
            }
          });

          const pickId = _lodash.default.get(record, 'pickId');

          const dropId = _lodash.default.get(record, 'dropId');

          if (pickId && tripStops[pickId]) {
            record.pickUpAddress = _lodash.default.get(tripStops[pickId], 'attributes.displayName');
          }

          if (dropId && tripStops[dropId]) {
            record.dropOffAddress = _lodash.default.get(tripStops[dropId], 'attributes.displayName');
          }
        } // Process tripTravelNeeds


        const tripTravelNeedsData = _lodash.default.get(jsonRecord, 'relationships.tripTravelNeeds.data', []);

        if (tripTravelNeedsData.length > 0) {
          const travelNeeds = tripTravelNeedsData.map(_ref4 => {
            let {
              id
            } = _ref4;
            const travelNeed = tripTravelNeeds[id];

            const count = _lodash.default.get(travelNeed, 'attributes.count', 0);

            const travelNeedTypeName = _lodash.default.get(travelNeed, 'relationships.travelNeedTypeName.data.id');

            const displayName = travelNeedTypeMap[travelNeedTypeName];
            return {
              count,
              travelNeedTypeName,
              displayName
            };
          }); // Classify capacityRequirements

          const capacityRequirements = travelNeeds.filter(need => need.count > 0);
          record.capacityRequirements = _lodash.default.map(capacityRequirements, need => `${need.count} ${need.displayName}`).join(', '); // Classify Traits

          const traits = _lodash.default.filter(travelNeeds, need => need.count === 0);

          record.traits = _lodash.default.map(traits, need => `${need.displayName}`).join(', ');
        } // Compute serviceStartTime and serviceEndTime using promiseTime and service-window


        const promiseTime = record.promiseTime;

        if (promiseTime && serviceWindow) {
          const promiseWindowStart = parseInt(serviceWindow.get('promiseWindowStart'), 10);
          const promiseWindowEnd = parseInt(serviceWindow.get('promiseWindowEnd'), 10);
          record.serviceStartTime = (0, _moment.default)(promiseTime).subtract(promiseWindowStart, 'minutes');
          record.serviceEndTime = (0, _moment.default)(promiseTime).add(promiseWindowEnd, 'minutes');
        }

        return record;
      });
      return tripData;
    },

    extractTripStopData(response) {
      const included = _lodash.default.get(response, 'included', []); // Lookup for tripStops, travelNeeds and routes from the included section.


      const tripStops = {};
      const routes = {};
      const tripTravelNeeds = {};
      included.forEach(jsonRecord => {
        if (jsonRecord.type === 'tripStop') {
          tripStops[jsonRecord.id] = jsonRecord;
        } else if (jsonRecord.type === 'route') {
          routes[jsonRecord.id] = jsonRecord;
        } else if (jsonRecord.type === 'tripTravelNeed') {
          tripTravelNeeds[jsonRecord.id] = jsonRecord;
        }
      });
      const tripStopsData = [];
      response.data.forEach(trip => {
        const tripId = _lodash.default.get(trip, 'attributes.tripId');

        const tripPk = _lodash.default.get(trip, 'id');

        const routeId = _lodash.default.get(trip, 'relationships.route.data.id');

        const pickId = _lodash.default.get(trip, 'relationships.pick.data.id');

        const dropId = _lodash.default.get(trip, 'relationships.drop.data.id');

        const travelNeeds = _lodash.default.get(trip, 'relationships.tripTravelNeeds.data', []);

        const routeInfo = routes[routeId];
        const tripPassengerCount = travelNeeds.map(travelNeed => {
          const travelNeedInfo = tripTravelNeeds[travelNeed.id];
          return travelNeedInfo ? _lodash.default.get(travelNeedInfo, 'attributes.count', 0) : 0;
        }).reduce((sum, count) => sum + count, 0); // Process only if the stop point exists in the lookup

        [pickId, dropId].forEach(id => {
          const jsonRecord = tripStops[id];

          if (jsonRecord) {
            const record = jsonRecord.attributes;

            const dwellInSeconds = _lodash.default.get(jsonRecord, 'attributes.dwell');

            if (_lodash.default.isNumber(dwellInSeconds)) {
              _lodash.default.set(jsonRecord, 'attributes.dwell', Math.floor(dwellInSeconds / 60));
            }

            record.address = jsonRecord.attributes.displayName;
            record.stopType = jsonRecord.id === pickId ? 'pick' : 'drop';
            record.tripPk = tripPk;
            record.tripId = tripId;
            record.routeId = routeId; // Extract route name and vehicle ID from the route info

            if (routeInfo) {
              record.routeName = _lodash.default.get(routeInfo, 'attributes.name');
              record.vehicleId = _lodash.default.get(routeInfo, 'relationships.vehicle.data.id');
            }

            if (record.stopType === 'pick') {
              record.pickPassengerCount = tripPassengerCount;
            } else if (record.stopType === 'drop') {
              record.dropPassengerCount = tripPassengerCount;
            }

            record.id = jsonRecord.id;
            record._key = jsonRecord.id;
            tripStopsData.push(record);
          }
        });
      });
      return tripStopsData;
    },

    addTraversalFilter(widgetInstanceId, modelName, records) {
      let selectionFilter = this.traversalFilters[widgetInstanceId];

      if (!selectionFilter) {
        selectionFilter = [];
        this.traversalFilters[widgetInstanceId] = selectionFilter;
      }

      records.forEach(record => {
        const index = selectionFilter.findIndex(filter => filter._key === record._key);

        if (index !== -1) {
          selectionFilter[index] = {
            model: modelName,
            _key: record._key,
            record
          };
        } else {
          selectionFilter.push({
            model: modelName,
            _key: record._key,
            record
          });
        }
      });
      this.trigger(_widgetEvents.ADD_TRAVERSAL_SELECTION_EVENT, widgetInstanceId, modelName); // Update the corresponding core entities in store by called

      records.forEach(record => {
        this.updateCoreEntityInStoreFromWidget(modelName, record);
      });
    },

    /**
     * store poluation entity, this need to be handled immediately to show data
     * @param model
     * @param widgetRecord
     * @param id
     */
    async storePopulateEntity(model, widgetRecord, id) {
      this.get('workspaceContext').manualStorePayload({
        data: {
          type: model,
          attributes: widgetRecord,
          id: id || widgetRecord.id
        }
      });
    },

    /**
     * push payload to normalized queue
     * @param model
     * @param widgetRecord
     * @param id
     */
    storeWidgetRecord(model, widgetRecord, id) {
      if (!model) {
        // eslint-disable-next-line no-console
        console.error(widgetRecord);
        return;
      }

      const storeRecord = _lodash.default.cloneDeep(widgetRecord);

      const normalizeQueue = this.get('normalizeQueue');
      const job = {
        action: 'push',
        payload: {
          data: {
            type: model,
            id: id || storeRecord.id,
            attributes: storeRecord
          }
        }
      };
      normalizeQueue.pushJob(job);
      this.get('work').start();
    },

    /**
     * Should be called after loading the core Entity Graph
     * @param widgetModelName
     * @param widgetRecord
     */
    updateCoreEntityInStoreFromWidget(widgetModelName, widgetRecord) {
      let model = this.get('widgetModelMap')[widgetModelName];
      model = this.get('getCoreModelNameByWidgetRecord')(model, widgetRecord);
      this.storeWidgetRecord(model, widgetRecord);
    },

    /**
     * update all core enity for widget records
     * @param widgetModelName
     * @param widgetRecord
     */
    updateAllCoreEntityStoreAction(widgetModelName, widgetRecords) {
      const model = this.get('widgetModelMap')[widgetModelName];

      _lodash.default.cloneDeep(widgetRecords).forEach(widgetRecord => {
        this.storeWidgetRecord(model, widgetRecord);
      });
    },

    removeTraversalFilter(widgetInstanceId, modelName, records) {
      const selectionFilter = this.traversalFilters[widgetInstanceId];

      if (selectionFilter) {
        records.forEach(record => {
          _lodash.default.remove(selectionFilter, item => item.model === modelName && item._key === record._key);
        });
        this.trigger(_widgetEvents.REMOVE_TRAVERSAL_SELECTION_EVENT, widgetInstanceId, modelName);
      } // Update the corresponding core entities in store by called


      records.forEach(record => {
        this.updateCoreEntityInStoreFromWidget(modelName, record);
      });
    },

    generateRQL(key, values) {
      if (!values.length) {
        return '';
      }

      const validValues = values.filter(data => !_lodash.default.isUndefined(data));
      const compoundRQL = inRQL(key, validValues);
      return compoundRQL;
    },

    combineInAndEqualFilters(inKey, values, equalFilters) {
      if (!values.length && !equalFilters.length) {
        return '';
      }

      let compoundInFiltersRQL = null;
      let compoundEqualsFiltersRQL = null;

      if (values) {
        compoundInFiltersRQL = this.generateRQL(inKey, values);
      }

      if (equalFilters) {
        const [firstQuery, ...rest] = equalFilters;
        compoundEqualsFiltersRQL = rest.reduce((acc, current) => {
          return orRQL(current, acc);
        }, firstQuery);
      }

      const allFilterRQL = [];

      if (compoundInFiltersRQL) {
        allFilterRQL.push(compoundInFiltersRQL);
      }

      if (compoundEqualsFiltersRQL) {
        allFilterRQL.push(compoundEqualsFiltersRQL);
      }

      if (!allFilterRQL) {
        return '';
      }

      if (allFilterRQL.length === 1) {
        return allFilterRQL[0];
      }

      return andRQL(allFilterRQL[0], allFilterRQL[1]);
    },

    async handleCoreEntitySelection(widgetModelName, field, ids) {
      const filter = inRQL(field, ids);
      const sortKey = 'id';
      const widgetInstanceId = `${widgetModelName}_${GLOBAL_WIDGET_INST}`;
      const dateQueryFields = this.getDateQueryFields();
      const selectedProviders = this.getSelectedProviders();
      const widgetRecords = await this.requestWidgetApi(widgetInstanceId, widgetModelName, filter, 0, ids.length, sortKey, dateQueryFields, selectedProviders);
      const model = this.get('widgetModelMap')[widgetModelName];
      widgetRecords.forEach(widgetRecord => {
        this.storeWidgetRecord(model, widgetRecord);
      });
      this.updateWidgetFilter(widgetInstanceId, widgetModelName, filter, 0, ids.length, sortKey);
    },

    // This function store populate entities
    async processWidgetRecordsForStore(widgetModelName, widgetRecords) {
      const model = this.get('widgetModelMap')[widgetModelName];
      widgetRecords.forEach(async widgetRecord => {
        const coreModel = this.get('getCoreModelNameByWidgetRecord')(model, widgetRecord);
        await this.storePopulateEntity(coreModel, widgetRecord);
      });
      const modelArray = Ember.isArray(model) ? model : [model];
      modelArray.forEach(m => {
        this.get('workspaceContext').manualReloadContextByModel(m);
      });
    },

    getEqualQuery(key, value) {
      return eqRQL(key, value);
    },

    getExistedQuery(key) {
      return neRQL(key, null);
    },

    async widgetsByFilterQuery(widgetModelName, filter) {
      let maxPageLimit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
      const sortKey = 'id';
      const widgetInstanceId = `${widgetModelName}_${GLOBAL_WIDGET_INST}`;
      const dateQueryFields = this.getDateQueryFields();
      const selectedProviders = this.getSelectedProviders();
      const widgetRecords = await this.requestWidgetApi(widgetInstanceId, widgetModelName, filter, 0, maxPageLimit, sortKey, dateQueryFields, selectedProviders);
      return widgetRecords;
    },

    /**
     *
     * @param filterKeyValues {routeId:[100,200], driverId:[2,3,4]}
     * @returns {*}
     */
    generateRQLFromObjKeys(filterKeyValues) {
      const allFilters = [];
      Object.entries(filterKeyValues).forEach(_ref5 => {
        let [field, values] = _ref5;
        allFilters.push(this.generateRQL(field, values));
      });
      const compoundFilter = allFilters.reduce((acc, current) => {
        return andRQL(current, acc);
      }, allFilters[0]);
      return compoundFilter;
    },

    _computeStoreModelKeys() {
      const store = this.get('store');
      let coreWidgetMap = CORE_WIDGET_MAPPING;
      const modelNameIds = {};

      if (_environment.default.APP.avlmLite) {
        coreWidgetMap = AVLM_CORE_WIDGET_MAPPING;
      }

      Object.keys(coreWidgetMap).map(modelName => {
        const widgetName = coreWidgetMap[modelName];
        modelNameIds[widgetName] = store.peekAll(modelName).mapBy('id');
      });
      return modelNameIds;
    },

    async updateUserSelection() {
      // Unit test avoid sending request through web socket
      if (_environment.default.APP.test || !_environment.default.APP.ENABLE_IQUX_USER_UPDATE) {
        return;
      }

      const modelNameIdList = this._computeStoreModelKeys();

      const data = JSON.stringify(modelNameIdList);
      const session = this.get('session');
      const socketClientId = this.get('socket').getSocketClientId();
      const url = `${_apiUrls.API.iquxService.host}/entitySelection?socket.clientId=${socketClientId}`;
      const response = await this.get('ajax').request(url, {
        method: 'POST',
        contentType: 'application/json',
        dataType: 'text',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`,
          'Content-Type': 'application/json'
        },
        data
      }).catch(e => {
        console.log(e); //eslint-disable-line no-console
      });
      return response;
    },

    maximizeWidget(widgetInstanceId) {
      this.trigger(_widgetEvents.WIDGET_MAXIMIZE_EVENT, widgetInstanceId);
    },

    minimizeWidget(widgetInstanceId) {
      this.trigger(_widgetEvents.WIDGET_MINIMIZE_EVENT, widgetInstanceId);
    },

    getDateQueryFields() {
      if (this.get('workspace.isScheduleDashboard')) {
        const schedule = this.get('workspace.currentSchedule');
        return {
          // To prevent data display from previous schedule in case of no schedule generation
          scheduleId: Ember.isPresent(schedule) && schedule.get('status') === 'scheduled' ? schedule.get('id') : null
        };
      }
    },

    async getTotalWidgetCount(widgetName, filter, dateQueryFields, selectedProviders) {
      const model = this.get('widgetModelMap')[widgetName];
      const apiEndpoint = MODEL_API_MAPPING[model];
      let queryTerms;

      if (this.get('workspace.isScheduleDashboard')) {
        queryTerms = [];
      } else {
        queryTerms = [`startDate=${dateQueryFields.startDate}`, `endDate=${dateQueryFields.endDate}`, `startDateUTC= ${dateQueryFields.startDateUTC}`, `endDateUTC=${dateQueryFields.endDateUTC}`, `selectedProviders=${selectedProviders}`];
      }

      const scheduleIdCond = eqRQL('scheduleId', dateQueryFields.scheduleId);
      let rqlFilter = encodeURIComponent(scheduleIdCond);

      if (!_lodash.default.isEmpty(filter)) {
        rqlFilter = andRQL(rqlFilter, filter);
      }

      queryTerms.push(`filter=${rqlFilter}`);
      const queryString = queryTerms.join('&');
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.schedulingService.host}/${apiEndpoint}?${queryString}`;
      const response = await this.get('ajax').request(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json'
      });
      let totalCount = response.data.length;

      if (widgetName === 'stopWidget') {
        const tripStops = _lodash.default.get(response, 'included', []).filter(item => item.type === 'tripStop');

        const uniqueTripStopIds = new Set(tripStops.map(tripStop => tripStop.id));
        totalCount = uniqueTripStopIds.size;
      }

      return totalCount;
    },

    exportServerData(widgetModelName, filterQueryString, headerNames, fieldIds, configTitle) {
      const queryString = this.getReportQuery(widgetModelName, filterQueryString);
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.iquxService.host}/widgetReport?${queryString}`;
      return this.get('ajax').request(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json'
      }).then(response => {
        const data = response.map(widgetData => widgetData.data);
        data.forEach(widgetRecord => {
          if (widgetRecord.plannedBreaks) {
            const objectLength = Object.keys(widgetRecord.plannedBreaks).length;
            widgetRecord.plannedBreaksCount = objectLength;
          }
        });
        this.downloadWidgetRecords(data, headerNames, fieldIds, configTitle);
      });
    },

    downloadServerData(widgetModelName, filterQueryString) {
      const queryString = this.getReportQuery(widgetModelName, filterQueryString);
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.iquxService.host}/widgetReport?${queryString}`;
      return this.get('ajax').request(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json'
      }).then(response => {
        const data = response.map(widgetData => widgetData.data);
        data.forEach(widgetRecord => {
          if (widgetRecord.plannedBreaks) {
            const objectLength = Object.keys(widgetRecord.plannedBreaks).length;
            widgetRecord.plannedBreaksCount = objectLength;
          }
        });
        return data;
      });
    },

    getReportQuery(widgetName, filter) {
      const dateQueryFields = this.getDateQueryFields();
      const selectedProviders = this.getSelectedProviders();
      const queryTerms = [`widgetName=${Ember.String.capitalize(widgetName)}`, `startDate=${dateQueryFields.startDate}`, `endDate=${dateQueryFields.endDate}`, `startDateUTC= ${dateQueryFields.startDateUTC}`, `endDateUTC=${dateQueryFields.endDateUTC}`, `selectedProviders=${selectedProviders}`];

      if (Ember.isPresent(dateQueryFields.scheduleId)) {
        queryTerms.push(`scheduleId=${dateQueryFields.scheduleId}`);
      }

      if (filter) {
        queryTerms.push(`data.filter=${filter}`);
      }

      const queryString = queryTerms.join('&');
      return queryString;
    },

    driverAvailibilityCheck(rowDataFieldName) {
      const keys = Object.keys(rowDataFieldName.firstObject);
      return keys.filter(availibility => {
        if (DAYS.includes(availibility)) {
          return rowDataFieldName.firstObject[availibility] === true;
        }
      });
    },

    downloadWidgetRecords(widgetRecords, headerNames, fieldIds, configTitle) {
      // convert to csv string
      const escapeCSV = cell => {
        if (cell === null || typeof cell === 'undefined') return '';
        const strCell = typeof cell === 'object' ? JSON.stringify(cell) : cell.toString();

        if (strCell.replace(/ /g, '').match(/[\s,"]/)) {
          return '"' + strCell.replace(/"/g, '""') + '"';
        }

        return strCell;
      };

      let csvData = widgetRecords.map(function (rowData) {
        const csvRowStr = fieldIds.map(function (fieldName) {
          return escapeCSV(rowData[fieldName]);
        }).join(',');
        return csvRowStr;
      });
      csvData.unshift(headerNames.join(',')); // add header column

      csvData = csvData.join('\r\n'); // download csvData

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-mm-ss');
      const filename = `${timestamp}_${configTitle}.csv`;
      const blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    getSelectedWidgetRecordsByModel(modelName) {
      const routeRecords = []; // eslint-disable-next-line guard-for-in

      for (const widgetInstance in this.traversalFilters) {
        const filters = this.traversalFilters[widgetInstance];

        for (const filter of filters) {
          if (filter.model === modelName) {
            routeRecords.push(filter.record);
          }
        }
      }

      return routeRecords;
    },

    /**
     * After filter is applied clear the old selection
     * @param widgetInstance
     */
    clearTraversalRecords(widgetInstance) {
      this.traversalFilters[widgetInstance] = [];
    },

    isMultiRecordsSelected(modelName) {
      const widgetRecords = this.getSelectedWidgetRecordsByModel(modelName);
      return widgetRecords.length > 1;
    },

    deselectAllWidgets() {
      this.clearAllTraversalRecords();
      this.trigger(_widgetEvents.DESELECT_ALL_WIDGET_EVENT);
      this.updateUserSelection();
      this.updateMap();
    },

    clearAllTraversalRecords() {
      this.traversalFilters = {};
    },

    getSelectedWidgetRecordsByWidgetInstance(widgetInstanceId) {
      const widgetInstancefilters = this.traversalFilters[widgetInstanceId] || [];
      return widgetInstancefilters.map(filter => filter.record);
    },

    /**
     * update global filter through socket
     */
    updateGlobalFilter(dateQueryFields, selectedProviders) {
      if (this.get('readyToLoadInitialData')) {
        this.get('socket').sendGlobalFilter(dateQueryFields.startDate, dateQueryFields.endDate, selectedProviders, dateQueryFields.startDateUTC, dateQueryFields.endDateUTC, dateQueryFields.scheduleId);
      }
    },

    /**
     * update widget filter through socket
     */
    // eslint-disable-next-line no-unused-vars
    updateWidgetFilter(widgetInstanceId, modelName, filter, offset, limit, sort) {// Widget wise filter is done on UI, Provider and Data filter is done on Server

      /* if (this.get('readyToLoadInitialData')) {
         this.get('socket').sendWidgetFilter(widgetInstanceId, modelName, decodeURIComponent(filter), offset, limit, sort);
       }*/
    },

    async getFixedRouteEngineParams() {
      await this.get('fixedRouteEngine').fetchFixedRouteEngine();
    },

    async getFutureBookings() {
      try {
        const bookings = await this.store.query('booking', {
          filter: `and(ge(legs.requestTime,${TOMORROW}),gt(legs.requestTime,${TODAY}))`
        });
        this.set('futureBookings', bookings.toArray());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
      }
    },

    async getFutureSubscriptions() {
      try {
        const subscriptions = await this.store.query('subscription', {
          filter: `and(ge(endDate,${TOMORROW}),gt(endDate,${TODAY}))`
        });
        this.set('futureSubscriptions', subscriptions.toArray());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
      }
    },

    /**
     * fetch driver availabilities by driver id when workspace ( dispatch, schedule ) is loaded
     * @param driverRecords
     * @returns {Promise<void>}
     */
    async loadDriverAvailabilities(driverRecords) {
      try {
        const driverIds = driverRecords.map(rec => rec.id);
        const query = 'in(\'driverId\',(' + driverIds + '))&include=driver';
        await this.store.query('driver-availability', {
          filter: query
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
      }
    }

  });

  _exports.default = _default;
});