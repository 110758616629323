define("adept-iq/pods/components/iq-widgets/stops-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig", "lodash", "adept-iq/utils/find-row-record", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "adept-iq/utils/rql-generators", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/version-check"], function (_exports, _component, _scheduleConfig, _lodash, _findRowRecord, _unwrapProxy, _mappedPermIds, _rqlGenerators, _asyncScheduleOperation, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend(_asyncScheduleOperation.default, _versionCheck.default, {
    classNames: ['stops-widget'],
    editModal: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    user: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    config: _scheduleConfig.default,
    defaultIncludes: INCLUDES,
    sourceRowId: null,
    dragTarget: null,
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected}', 'selectedWidgetRecord', 'isLoading', function () {
      return this._dynamicScheduleActions();
    }),
    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dragDirection: Ember.computed('dragTarget', 'sourceRowId', function () {
      const targetRow = this.get('dragTarget');

      if (targetRow) {
        const rows = this.get('dragRowGroup');
        const targetIdx = rows.indexOf((0, _findRowRecord.findRowRecordByElement)(targetRow, rows));
        const sourceIdx = rows.indexOf(rows.findBy('rowId', this.get('sourceRowId')));
        return sourceIdx - targetIdx;
      }

      return null;
    }).readOnly(),
    dragOverClass: Ember.computed('dragDirection', function () {
      const direction = this.get('dragDirection') < 0 ? 'below' : 'above';
      return ` drag-target-${direction} drag-target`;
    }),

    init() {
      this.set('config', _scheduleConfig.default);

      this._super(...arguments);
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        // if it is a trip active context
        // only include the pick and drop stops
        if (this.get('activeContext.topActiveContext').tripActiveContext()) {
          return records.filter(record => {
            return ['pick', 'drop'].includes(record.get('stopType'));
          });
        }

        return records;
      });
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    actions: {
      onColumnClick() {
        this._super(...arguments);
      },

      onSearchButtonClick() {
        this._super(...arguments);
      },

      onFilterButtonClick() {
        this._super(...arguments);
      },

      async onRowRightClick() {
        return this._super(...arguments);
      }

    },

    didInsertElement() {
      this._super(...arguments);

      this._initScheduleSingleActions();
    },

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      this.set('sourceRowId', row.get('rowId'));
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'stops');
      event.dataTransfer.setData('text', dragId);
    },

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.set('dragTarget', rowElement);
      this.$(`#${rowElement.id}`).addClass(this.get('dragOverClass'));
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
    },

    drop(event) {
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
      const table = this.get('table');
      const rows = this.get('dragRowGroup'); // let sourceRowRecord = rows.findBy('rowId', this.get('sourceRowId'));
      // // User might drop elements that are not Stops widget rows.
      // if (isBlank(sourceRowRecord)) {
      //   return;
      // }

      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, rows);

      const _rows = rows.toArray();

      const targetRowIdx = _rows.indexOf(targetRowRecord) + (this.get('dragDirection') < 0 ? 0 : 0);
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
      table.notifyPropertyChange('rows');

      _rows.removeObject(sourceRowRecord);

      _rows.insertAt(targetRowIdx, sourceRowRecord);

      const result = this.doReorder(sourceRowRecord, targetRowRecord);

      if (result) {
        rows.setObjects(_rows);
      }

      table.notifyPropertyChange('rows');
    },

    dragEnd() {
      this.set('sourceRowId', null);
      this.set('dragTarget', null);
      this.get('dragCoordinator').clearSourceRows();
    },

    _initScheduleSingleActions() {
      this.set('tableActions', [{
        id: 'print',
        permIds: [_mappedPermIds.default.downloadRoutes],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'Activity Log',
        action: model => {
          const routeName = model.get('route.name');
          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: (0, _unwrapProxy.unwrapProxy)(model.get('route'))
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
    },

    _dynamicScheduleActions() {
      const activityLog = this.get('activityLog');
      const row = this.getSelectedCoreModel();
      if (!row) return []; //const selectedWidgetRecord = this.get('selectedWidgetRecord');

      const genericActions = [activityLog];
      return genericActions;
    },

    applyTransformationData(widgetRecords) {
      if (widgetRecords.transformed) {
        return widgetRecords;
      }

      const recordsByRoute = _lodash.default.groupBy(widgetRecords.filter(record => record.routeId), 'routeId');

      _lodash.default.forEach(recordsByRoute, records => {
        //Compute Ordinal Value for pullinGarage
        let lastOrdinal = _lodash.default.maxBy(records, 'plannedRouteOrdinal')?.plannedRouteOrdinal || 0;

        const orderedRecords = _lodash.default.sortBy(records, record => {
          if (record.stopType === 'pullinGarage' && !record.plannedRouteOrdinal) {
            record.plannedRouteOrdinal = ++lastOrdinal;
          }

          return record.plannedRouteOrdinal || Number.MAX_VALUE;
        }); // Compute currPassengerCount


        let currPassengerCount = 0;
        orderedRecords.forEach(record => {
          const pickCount = record.pickPassengerCount || 0;
          const dropCount = record.dropPassengerCount || 0;
          currPassengerCount += pickCount - dropCount;
          record.currPassengerCount = currPassengerCount;
        });
      });

      return widgetRecords;
    },

    // eslint-disable-next-line no-unused-vars
    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      const rowStyleHandler = '';
      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedStopRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('stopWidget');
      const selectedRouteSet = new Set();
      selectedStopRecords.forEach(data => {
        if (data.routeId) {
          selectedRouteSet.add(data.routeId);
        }
      });

      if (selectedRouteSet.size >= maxLimitByRouteSelection || selectedStopRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});