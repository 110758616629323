define("adept-iq/serializers/trip-stop", ["exports", "adept-iq/serializers/-raasService"], function (_exports, _raasService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    normalize(modelClass, resourceHash) {
      const relationships = resourceHash.relationships || {};

      if (Ember.isNone(relationships.route) && !Ember.isNone(resourceHash.attributes.routeId)) {
        relationships.route = {
          data: {
            type: 'route',
            id: resourceHash.attributes.routeId
          }
        };
      }

      resourceHash.relationships = relationships;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      payload.data.relationships = payload.data.relationships || {};
      return payload;
    }

  });

  _exports.default = _default;
});