define("adept-iq/models/schedule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    optimizationId: attr('number'),
    optimizationStatus: attr('string'),
    metric: belongsTo('metric', {
      async: false
    })
  });

  _exports.default = _default;
});