define("adept-iq/pods/components/optimize-schedule/component", ["exports", "adept-iq/config/icon-paths", "lodash"], function (_exports, _iconPaths, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['optimize-schedule'],
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    widget: Ember.inject.service(),
    selectedOptionLeft: '',
    selectedOptionRight: '',
    selectedDate: '',
    availableStrategies: null,
    isLoading: true,
    isOptimizeDisabled: false,
    currentSchedule: null,
    updatedSchedule: null,
    isUploaded: false,

    init() {
      this._super(...arguments);

      this.loadOptimizationStrategies();
    },

    async loadOptimizationStrategies() {
      try {
        let strategies = await this.store.findAll('engine-strategy');
        strategies = strategies.filter(strategy => strategy.id !== 'default');
        strategies = (0, _lodash.orderBy)(strategies, ['id'], ['desc']);
        this.set('availableStrategies', strategies);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading strategies:', error);
        this.notifications.error('Failed to load optimization strategies.');
      } finally {
        this.set('isLoading', false);
      }
    },

    onScheduleGenerationSuccessOrFailure: Ember.observer('workspace.scheduleReoptimizeStatus', 'workspace.reoptimizeInProgress', function () {
      const workspace = this.get('workspace'); // Reoptimization is in progress

      if (workspace.reoptimizeInProgress) {
        return;
      } // Schedule Generation Processed , enable the optimized btn and close the screen


      if (workspace.scheduleReoptimizeStatus === 'success' || workspace.scheduleReoptimizeStatus === 'fail') {
        this.set('isOptimizeDisabled', false);

        if (workspace.scheduleReoptimizeStatus === 'fail') {
          const currentSchedule = this.get('currentSchedule');
          const notifications = this.get('notifications');
          workspace.set('currentSchedule', currentSchedule);
          notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
        }

        if (workspace.scheduleReoptimizeStatus === 'success') {
          this.updateScheduleOnSuccess();
        }

        this.send('onXButtonClick');
      }
    }),

    unloadAllRecords(modelName) {
      this.store.peekAll(modelName).forEach(record => record.unloadRecord());
    },

    async updateScheduleOnSuccess() {
      const workspace = this.get('workspace');
      const updatedSchedule = this.get('updatedSchedule');
      const schedulename = updatedSchedule.get('name');
      this.unloadAllRecords('route');
      this.unloadAllRecords('trip-stop');
      this.unloadAllRecords('trip');
      await workspace.updateCurrentSchedule(schedulename);
      await workspace.refreshWidgets();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    get iconPaths() {
      return {
        sandbox: _iconPaths.default.actionMenu.sandbox,
        upload: _iconPaths.default.actionMenu.upload,
        download: _iconPaths.default.actionMenu.download,
        checkmark: _iconPaths.default.actionMenu.checkmark
      };
    },

    actions: {
      async onOptimizeBtnClick(side) {
        const workspace = this.get('workspace');
        const notifications = this.get('notifications');
        const selectedDate = this.get('selectedDate');
        const currentSchedule = workspace.get('currentSchedule');
        this.set('isOptimizeDisabled', true);
        this.set('currentSchedule', currentSchedule);
        let selectedStrategy, updatedSchedule;

        if (side === 'left') {
          selectedStrategy = this.get('selectedOptionLeft');
          updatedSchedule = currentSchedule;
        } else if (side === 'right') {
          selectedStrategy = this.get('selectedOptionRight');
          await workspace.updateCurrentSchedule(selectedDate);
          updatedSchedule = workspace.get('currentSchedule');
        }

        if (!updatedSchedule) {
          notifications.warning('Trips not found for optimization, please upload trips .');
          this.set('isOptimizeDisabled', false);
          workspace.set('currentSchedule', currentSchedule);
          return;
        }

        this.set('updatedSchedule', updatedSchedule);
        workspace.pushState('schedule-generate', {
          currentSchedule: updatedSchedule,
          radioValue: 'optimize',
          strategyName: selectedStrategy
        });
      },

      onXButtonClick() {
        const workspace = this.get('workspace');
        workspace.set('isOptimizeScheduleOpen', false);
      },

      handleUploadSuccess(date) {
        this.set('selectedDate', date);
        this.set('isUploaded', true);
      },

      updateOptionLeft(selectedOption) {
        this.set('selectedOptionLeft', selectedOption);
      },

      updateOptionRight(selectedOption) {
        this.set('selectedOptionRight', selectedOption);
      }

    }
  });

  _exports.default = _default;
});