define("adept-iq/pods/components/schedule-generate-modal/component", ["exports", "ember-concurrency", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    equal
  } = Ember.computed;
  const TIMEOUT_DELAY = 10000; // 10 second tries

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    timeout: TIMEOUT_DELAY,
    // passed in from stackFrame
    currentSchedule: readOnly('stackFrame.options.currentSchedule'),
    radioValue: readOnly('stackFrame.options.radioValue'),
    strategyName: readOnly('stackFrame.options.strategyName'),
    isOptimized: equal('radioValue', 'optimize'),
    title: 'Optimization',
    progress: 0,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.onLoadTask.perform();
    },

    didRender() {
      this._super(...arguments);
    },

    onReset() {
      this.get('workspace').popState('schedule-generate');
    },

    onLoadTask: (0, _emberConcurrency.task)(function* () {
      const isOptimized = this.get('isOptimized');
      const onGenerateSchedule = this.onGenerateSchedule;

      try {
        if (isOptimized) {
          this.set('workspace.triggeredReoptimize', true);
          this.set('workspace.reoptimizeInProgress', true);
          yield onGenerateSchedule.perform();
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          console.error(e); // eslint-disable-line no-console
        }
      } finally {
        yield (0, _emberConcurrency.timeout)(2000);
        this.set('workspace.triggeredReoptimize', false);
        this.set('workspace.reoptimizeInProgress', false);
        this.onReset();
      }
    }),
    onGenerateSchedule: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('currentSchedule');
      const strategyName = this.get('strategyName');
      let res = null;

      try {
        this.set('workspace.scheduleReoptimizeStatus', 'progress');
        res = yield this.createScheduleAsyncOperation.perform(schedule, 'generate', {
          operationData: {
            systemType: 'raas',
            strategy: strategyName
          }
        });

        if (res) {
          //this.get('notifications').success('SCHEDULE SUCCESSFULLY OPTIMIZED');
          this.set('workspace.scheduleReoptimizeStatus', 'success');
        } else {
          this.set('workspace.scheduleReoptimizeStatus', 'fail');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          this.set('workspace.scheduleReoptimizeStatus', 'fail');
          console.warn(e); // eslint-disable-line no-console

          throw e;
        }
      }

      return Promise.resolve(res);
    })
  });

  _exports.default = _default;
});