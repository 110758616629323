define("adept-iq/models/route", ["exports", "ember-data", "adept-iq/mixins/otp-label"], function (_exports, _emberData, _otpLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Scheduling Route Entity
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_otpLabel.default, {
    session: Ember.inject.service(),
    problemId: attr('string'),
    routeId: attr('string'),
    vehicleId: attr('string'),
    routeShiftIndex: attr('string'),
    routeName: attr('string'),
    vehicleName: attr('string'),
    routeTemplateId: attr('string'),
    instanceId: attr('string'),
    scheduledPolyline: belongsTo('schedule-polyline', {
      async: false
    }),
    tripStops: hasMany('trip-stop')
  });

  _exports.default = _default;
});