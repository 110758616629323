define("adept-iq/serializers/metric", ["exports", "adept-iq/serializers/-raasService"], function (_exports, _raasService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    normalize(modelClass, resourceHash) {
      return this._super(modelClass, resourceHash);
    }

  });

  _exports.default = _default;
});