define("adept-iq/adapters/schedule", ["exports", "adept-iq/adapters/-raasService"], function (_exports, _raasService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    urlForFindRecord(id) {
      const resouceName = 'problem';
      const raasServiceHost = this.get('host');
      const baseUrl = `${raasServiceHost}/${resouceName}/${id}`;
      return baseUrl;
    },

    urlForFindAll() {
      const resourceName = 'problem';
      const raasServiceHost = this.get('host');
      const baseUrl = `${raasServiceHost}/${resourceName}`;
      return baseUrl;
    },

    bulkDelete() {
      const session = this.get('session');
      const store = this.get('store'); // Construct the API URL

      const url = `${this.host}/problem`; // Perform the DELETE request

      return this.get('ajaxService').request(url, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(() => {
        // Unload all records of the relevant model from the store
        store.peekAll('schedule').forEach(record => {
          store.unloadRecord(record);
        });
      });
    }

  });

  _exports.default = _default;
});