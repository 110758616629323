define("adept-iq/services/polyline", ["exports", "adept-iq/config/api-urls", "@mapbox/polyline", "adept-iq/mixins/async-schedule-operation", "adept-iq/utils/unwrapProxy", "ember-concurrency"], function (_exports, _apiUrls, _polyline, _asyncScheduleOperation, _unwrapProxy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_CONCURRENCY = 2;

  var _default = Ember.Service.extend(_asyncScheduleOperation.default, {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),

    async getPerformedPolyline(routeId) {
      const session = this.get('session');
      return await this.get('ajax').request(_apiUrls.API.avlmService.host + '/performed-polyline?routeId=' + routeId, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        return response.data;
      });
    },

    fetchPerformedPolyline: (0, _emberConcurrency.task)(function* (routeId, dispatchRouteId) {
      const store = this.get('store');
      let stopPoints = [];
      yield this.getPerformedPolyline(routeId).then(response => {
        stopPoints = response;
      });
      if (Ember.isEmpty(stopPoints)) return [];
      const coords = stopPoints.reduce((arr, stopPoint) => {
        const points = stopPoint.polylineArray;
        if (Ember.isEmpty(points)) return arr;
        const perturbedPoints = [points.lat, points.lng]; // should flatten to single line

        arr.push(perturbedPoints);
        return arr;
      }, []);
      const payload = {
        data: {
          id: `polyline-${routeId}`,
          type: 'polyline',
          attributes: {
            coords
          }
        },
        included: [{
          id: dispatchRouteId,
          type: 'dispatch-route',
          relationships: {
            performedPolyline: {
              data: {
                id: `polyline-${routeId}`,
                type: 'polyline'
              }
            }
          }
        }]
      };
      store.push(payload);
    }).enqueue().maxConcurrency(MAX_CONCURRENCY),

    deserialize(serialized) {
      if (Ember.isEmpty(serialized)) return [];
      return _polyline.default.decode(serialized);
    },

    fetchScheduledPolyline: (0, _emberConcurrency.task)(function* (route) {
      const store = this.get('store');
      const routeId = route.get('id');
      const scheduleRoute = (0, _unwrapProxy.unwrapProxy)(route);
      const points = yield this.getScheduledPolyline(scheduleRoute);
      let coords = this.deserialize(points); // regarding this bug (NYAAR-14584)
      // removing zero lat and long pairs from cords [0,0]

      coords = coords.reduce((acc, cord) => {
        const xCord = cord[0];
        const yCord = cord[1];

        if (xCord !== 0 && yCord !== 0) {
          acc.push(cord);
        }

        return acc;
      }, []);
      const payload = {
        data: {
          id: `schedule-${routeId}`,
          type: 'schedule-polyline',
          attributes: {
            coords
          }
        },
        included: [{
          id: routeId,
          type: route._internalModel.modelName,
          relationships: {
            scheduledPolyline: {
              data: {
                id: `schedule-${routeId}`,
                type: 'schedule-polyline'
              }
            }
          }
        }]
      };
      store.push(payload);
    }).enqueue().maxConcurrency(MAX_CONCURRENCY),

    async getScheduledPolyline(route) {
      if (Ember.isEmpty(route)) return [];
      const baseUrl = _apiUrls.API.raasService.host;
      const problemId = route.get('problemId'); //Fields needed to form routeId for polyline api

      const vehicleId = route.get('vehicleId');
      const routeTemplateId = route.get('routeTemplateId');
      const instanceId = route.get('instanceId');
      const routeShiftIndex = route.get('routeShiftIndex');
      const session = this.get('session');
      const routeId = routeTemplateId ? `${routeTemplateId}-${instanceId}.${routeShiftIndex}` : `${vehicleId}.${routeShiftIndex}`;

      try {
        const response = await this.get('ajax').request(`${baseUrl}/problem/${problemId}/polyline?routeId=${routeId}`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });

        if (!response || !response.plannedPolyline) {
          throw new Error('No polyline data received.');
        }

        return response.plannedPolyline;
      } catch (e) {
        this.get('notifications').warning('UNABLE TO DISPLAY ROUTE ON MAP. CONTACT ADMINISTRATOR.');
        return null;
      }
    }

  });

  _exports.default = _default;
});