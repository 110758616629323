define("adept-iq/pods/components/iq-widgets/map-widget/config/marker-leaflet", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stopIconSize = [23.0, 23.0];
  const stopIconAnchor = stopIconSize.map(x => x / 2.0);
  var _default = {
    pick1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    pick2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    pick3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    pick4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    pick5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    drop1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    drop2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    drop3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    drop4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    drop5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullin1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePullinPlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullin2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePullinPlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullin3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePullinPlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullin4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePullinPlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullin5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePullinPlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullout1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePulloutPlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullout2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePulloutPlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullout3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePulloutPlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullout4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePulloutPlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    },
    garagePullout5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePulloutPlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor
      })
    }
  };
  _exports.default = _default;
});