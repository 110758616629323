define("adept-iq/utils/api-response-transformer", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generates a structured ID for a record.
   * @param {string} problemId - Problem identifier.
   * @param {string} routeName - Route name.
   * @param {string} routeShiftIndex - Route shift index.
   * @param {string} [ordinal] - Optional ordinal for stops.
   * @returns {string} - Unique record ID.
   */
  function generateRecordId(problemId, routeName, routeShiftIndex) {
    let ordinal = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    const shiftPart = routeShiftIndex !== null ? `S${routeShiftIndex}` : '';
    const ordinalPart = ordinal !== null ? `-O${ordinal}` : '';
    return `P${problemId}-R${routeName}${shiftPart}${ordinalPart}`;
  }
  /**
   * Transforms API response for route into a structured model format.
   * @param {Array} data - Array of route objects.
   * @param {string} inputId - Problem identifierther numeric (e.g: 9) or structured (e.g: P9-R2S1)
   * @returns {Array} - Normalized route data.
   */


  function transformRouteResponse(data, inputId) {
    const dataArray = Array.isArray(data) ? data : [data];
    const inputIdStr = String(inputId);
    const problemMatch = inputIdStr.match(/P(\d+)/);
    const shiftMatch = inputIdStr.match(/S(\d+)/);

    const problemId = _lodash.default.toNumber(_lodash.default.get(problemMatch, '[1]', inputIdStr));

    const shiftIndex = _lodash.default.toNumber(_lodash.default.get(shiftMatch, '[1]', -1));

    return dataArray.flatMap(routeItem => {
      return routeItem.shifts.filter(shift => shiftIndex === -1 || _lodash.default.get(shift, 'shift.index', -1) === shiftIndex).map(shift => {
        const routeShiftIndex = shift.shift?.index ?? '';
        const routeId = generateRecordId(problemId, routeItem.routeName, routeShiftIndex);
        return {
          id: routeId,
          _key: routeId,
          problemId,
          routeId,
          routeShiftIndex,
          vehicleId: routeItem.id,
          vehicleName: routeItem.routeName,
          routeName: routeItem.routeName,
          routeTemplateId: routeItem.routeTemplateId,
          instanceId: routeItem.instanceId
        };
      });
    });
  }
  /**
   * Transforms API response for stops into structured model format.
   * @param {Array} data - Array of stop objects.
   * @param {string} problemId - Problem identifier.
   * @returns {Array} - Normalized stop data.
   */


  function transformStopResponse(data, problemId) {
    const dataArray = Array.isArray(data) ? data : [data];
    let stops = dataArray.map(stop => {
      /*const routeId = stop.routeShiftIndex ?
        `${stop.routeName}.${stop.routeShiftIndex}` :
        stop.routeName;*/
      return {
        id: generateRecordId(problemId, stop.routeName, stop.routeShiftIndex, stop.ordinal),
        _key: generateRecordId(problemId, stop.routeName, stop.routeShiftIndex, stop.ordinal),
        routeId: generateRecordId(problemId, stop.routeName, stop.routeShiftIndex),
        vehicleName: stop.routeName,
        routeShiftIndex: stop.routeShiftIndex,
        routeName: stop.routeName,
        plannedRouteOrdinal: stop.ordinal,
        JobId: stop.jobId,
        tripId: stop.jobId,
        plannedEta: stop.eta,
        stopType: stop.type,
        plannedTravelTimeToNext: stop.timeToNext ?? 0,
        plannedDistanceToNext: stop.distanceToNext ?? 0,
        plannedSlackTime: stop.waitTime ?? 0,
        lat: stop.location?.[0] ?? null,
        lng: stop.location?.[1] ?? null
      };
    }); // Remove stops where vehicleName is 'unassigned'

    stops = stops.filter(stop => stop.vehicleName && stop.vehicleName.toLowerCase() !== 'unassigned'); // Group stops by routeId

    const groupedStops = _lodash.default.groupBy(stops, 'routeId');

    _lodash.default.forEach(groupedStops, stopsArray => {
      _lodash.default.sortBy(stopsArray, 'plannedRouteOrdinal');

      let lastValidStop = null;

      _lodash.default.forEach(stopsArray, stop => {
        if (stop.stopType === 'break' && lastValidStop && _lodash.default.isNull(stop.lat) && _lodash.default.isNull(stop.lng)) {
          stop.lat = stop.lat ?? lastValidStop.lat;
          stop.lng = stop.lng ?? lastValidStop.lng;
        }

        if (stop.lat !== null && stop.lng !== null) {
          lastValidStop = stop;
        }
      });
    }); // Flatten grouped stops back into an array


    return _lodash.default.flatMap(groupedStops);
  }

  const transformer = {
    transformRouteResponse,
    transformStopResponse
  };
  var _default = transformer;
  _exports.default = _default;
});