define("adept-iq/mixins/async-schedule-operation", ["exports", "ember-concurrency", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const raasHost = _apiUrls.API.raasService.host;
  const TIMEOUT_DELAY = 2000; // 2 seconds tries

  const RETRY_LIMIT = 3; // Maximum number of retries

  var _default = Ember.Mixin.create({
    timeout: TIMEOUT_DELAY,
    retryLimit: RETRY_LIMIT,

    init() {
      this._super(...arguments);

      const ajax = this.get('ajax');
      const session = this.get('session');
      (true && !(Ember.isPresent(ajax)) && Ember.assert('mixin requires components, routes, controllers to provide a ajax service', Ember.isPresent(ajax)));
      (true && !(Ember.isPresent(session)) && Ember.assert('mixin requires components, routes, controllers to provide a session service', Ember.isPresent(session)));
    },

    /**
     * @param problem - The scheduling problem data
     */
    createScheduleAsyncOperation: (0, _emberConcurrency.task)(function* (problem) {
      const session = this.get('session');
      const job = yield this.get('ajax').post(raasHost + '/optimization?save=true', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: problem
      });
      if (!job) return false; //Job Scheduling Request Accepted or Succeeded

      const isJobSuccess = job.status === 202 || job.status === 200;
      return {
        job,
        isJobSuccess
      };
    }).enqueue(),
    pollScheduleOperationState: (0, _emberConcurrency.task)(function* () {
      const session = this.get('session');
      const jobs = yield this.get('ajax').request(raasHost + '/schedule-async-operation/?sort=id', {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      if (!jobs && !jobs.data.length) return false;
      const job = jobs.data[jobs.data.length - 1];
      if (!job) return false;
      const data = {
        data: {
          id: job.id,
          attributes: job.attributes,
          relationships: job.relationships
        }
      };
      return yield this.get('asyncOperationState').perform(data, true);
    }).enqueue(),

    /**
     * @param job - Response payload to poll for optimization results.
     */
    asyncOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');
      let retryCount = 0; // Ensure job has an id before proceeding

      if (!job || !job.id) {
        throw new Error("Invalid job object: missing 'id' field");
      } // Variable for polling response


      let pollingJob;

      do {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay);

        try {
          pollingJob = yield this.get('ajax').request(raasHost + '/raas-async-operation/' + job.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          retryCount += 1; // Retry in case of Timeout from API

          if (retryCount > this.get('retryLimit')) {
            throw new Error(`Failed after ${RETRY_LIMIT} attempts: ${e.message}`);
          }
        }
      } while (pollingJob.state !== 'success' && pollingJob.state !== 'failure' && pollingJob.state !== 'rejected');

      const isJobSuccess = pollingJob.state === 'success';
      const jsonData = pollingJob;
      return {
        jsonData,
        isJobSuccess
      };
    }).drop()
  });

  _exports.default = _default;
});