define("adept-iq/pods/components/iq-widgets/map-widget/config/marker", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stopIconSize = [23.0, 23.0];
  var _default = {
    pick1: {
      icon: {
        src: _iconPaths.default.stops.pickPlanned1,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    pick2: {
      icon: {
        src: _iconPaths.default.stops.pickPlanned2,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    pick3: {
      icon: {
        src: _iconPaths.default.stops.pickPlanned3,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    pick4: {
      icon: {
        src: _iconPaths.default.stops.pickPlanned4,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    pick5: {
      icon: {
        src: _iconPaths.default.stops.pickPlanned5,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    drop1: {
      icon: {
        src: _iconPaths.default.stops.dropPlanned1,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    drop2: {
      icon: {
        src: _iconPaths.default.stops.dropPlanned2,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    drop3: {
      icon: {
        src: _iconPaths.default.stops.dropPlanned3,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    drop4: {
      icon: {
        src: _iconPaths.default.stops.dropPlanned4,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    drop5: {
      icon: {
        src: _iconPaths.default.stops.dropPlanned5,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullin1: {
      icon: {
        src: _iconPaths.default.stops.garagePullinPlanned1,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullin2: {
      icon: {
        src: _iconPaths.default.stops.garagePullinPlanned2,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullin3: {
      icon: {
        src: _iconPaths.default.stops.garagePullinPlanned3,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullin4: {
      icon: {
        src: _iconPaths.default.stops.garagePullinPlanned4,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullin5: {
      icon: {
        src: _iconPaths.default.stops.garagePullinPlanned5,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullout1: {
      icon: {
        src: _iconPaths.default.stops.garagePulloutPlanned1,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullout2: {
      icon: {
        src: _iconPaths.default.stops.garagePulloutPlanned2,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullout3: {
      icon: {
        src: _iconPaths.default.stops.garagePulloutPlanned3,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullout4: {
      icon: {
        src: _iconPaths.default.stops.garagePulloutPlanned4,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    garagePullout5: {
      icon: {
        src: _iconPaths.default.stops.garagePulloutPlanned5,
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    }
  };
  _exports.default = _default;
});