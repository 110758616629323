define("adept-iq/serializers/schedule", ["exports", "adept-iq/serializers/-raasService", "lodash"], function (_exports, _raasService, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    attrs: {},

    serialize() {
      const json = this._super(...arguments);

      return json;
    },

    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let jsonApiPayload;

      if (Array.isArray(payload)) {
        jsonApiPayload = {
          data: payload.map(item => {
            return {
              id: item.id,
              type: primaryModelClass.modelName,
              attributes: {
                optimizationId: item.raasAsyncOperation.id,
                optimizationStatus: item.raasAsyncOperation.state
              },
              relationships: {
                metric: {
                  data: {
                    id: item.id,
                    type: 'metric'
                  }
                }
              }
            };
          }),
          included: payload.map(item => {
            const stats = _lodash.default.get(item, 'raasAsyncOperation.results.statistics', {});

            return {
              id: item.id,
              // Use payload.id directly as metricsId
              type: 'metric',
              attributes: {
                objective: _lodash.default.get(item, 'data.objective', null),
                routes: _lodash.default.get(stats, 'vehicles.used', null),
                totalMiles: _lodash.default.get(stats, 'distance.totalDistance', null),
                totalHours: _lodash.default.get(stats, 'times.totalHours', null),
                scheduledTrips: _lodash.default.get(stats, 'jobs.scheduledTasks', null),
                unscheduledTrips: _lodash.default.get(stats, 'jobs.unassignedTasks', null),
                unusedRoutes: _lodash.default.get(stats, 'vehicles.unused', null),
                revenueHours: _lodash.default.get(stats, 'times.revenueHours', null),
                revenueMiles: _lodash.default.get(stats, 'distance.revenueDistance', null)
              }
            };
          })
        };
      } else {
        const stats = _lodash.default.get(payload, 'raasAsyncOperation.results.statistics', {});

        jsonApiPayload = {
          data: {
            id: payload.id,
            type: primaryModelClass.modelName,
            attributes: {
              optimizationId: payload.raasAsyncOperation.id,
              optimizationStatus: payload.raasAsyncOperation.state
            },
            relationships: {
              metric: {
                data: {
                  id: payload.id,
                  type: 'metric'
                }
              }
            }
          },
          included: [{
            id: payload.id,
            // Use payload.id directly as metricsId
            type: 'metric',
            attributes: {
              objective: _lodash.default.get(payload, 'data.objective', null),
              routes: _lodash.default.get(stats, 'vehicles.used', null),
              totalMiles: _lodash.default.get(stats, 'distance.totalDistance', null),
              totalHours: _lodash.default.get(stats, 'times.totalHours', null),
              scheduledTrips: _lodash.default.get(stats, 'jobs.scheduledTasks', null),
              unscheduledTrips: _lodash.default.get(stats, 'jobs.unassignedTasks', null),
              unusedRoutes: _lodash.default.get(stats, 'vehicles.unused', null),
              revenueHours: _lodash.default.get(stats, 'times.revenueHours', null),
              revenueMiles: _lodash.default.get(stats, 'distance.revenueDistance', null)
            }
          }]
        };
      }

      const normalizedResponse = this._super(store, primaryModelClass, jsonApiPayload, id, requestType);

      return normalizedResponse;
    }

  });

  _exports.default = _default;
});