define("adept-iq/pods/components/user-profile/api-call-record-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Past API Calls',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    isFormControls: true,
    showSelectedRowLength: false,
    modelName: 'raas-api-call-record',
    rowSelectionType: '',
    columns: [{
      id: 'id',
      index: 0,
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      defaultWidth: 100
    }, {
      id: 'createdAt',
      index: 1,
      type: 'date',
      label: 'Timestamp',
      format: 'YYYY-MM-DD HH:mm',
      valuePath: 'createdAt',
      defaultWidth: 185,
      searchable: true
    }, {
      id: 'status',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'state',
      defaultWidth: 140
    }, {
      id: 'fleetSize',
      index: 3,
      type: 'number',
      label: 'Number Of Vehicles',
      valuePath: 'fleetSize',
      defaultWidth: 150
    }, {
      id: 'numJobs',
      index: 4,
      type: 'number',
      label: 'Number Of Jobs',
      valuePath: 'numJobs',
      defaultWidth: 150
    }, {
      id: 'numTasks',
      index: 5,
      type: 'number',
      label: 'Number of Tasks',
      valuePath: 'numTasks',
      defaultWidth: 150
    }, {
      id: 'runTime',
      index: 6,
      type: 'number',
      label: 'Processing Time',
      valuePath: 'runTime',
      defaultWidth: 150
    }]
  };
  _exports.default = _default;
});