define("adept-iq/classes/markers/stops/schedule-stop", ["exports", "adept-iq/classes/markers/stops/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // see `app/pods/components/iq-widgets/map-widget/config/marker.js`
  var _default = _base.default.extend({
    record: null,
    // this is a trip-stop or route-break
    rowIndex: 1,
    // `BaseMarker` overrides
    isActive: true,
    lat: Ember.computed.readOnly('record.lat'),
    lng: Ember.computed.readOnly('record.lng'),
    // `BaseStopMarker` overrides
    stopType: Ember.computed.readOnly('record.computedStopType'),
    stopId: Ember.computed.readOnly('record.id'),
    label: Ember.computed.oneWay('iqStopLabel'),
    isHighLighted: Ember.computed.or('record.{isHighLighted,trip.isHighLighted,trip.rider.isHighLighted}'),
    iqStopLabel: Ember.computed('stopType', 'id', function () {
      const stopType = this.get('stopType');
      const stopId = this.get('id');
      return `${Ember.String.capitalize(stopType)} stop ${stopId}`;
    }),
    stopLabel: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');
      return `${Ember.String.capitalize(stopType)} stop`;
    }),
    stopStyle: Ember.computed('typeStylePrefix', 'rowIndex', function () {
      const typeStylePrefix = this.get('typeStylePrefix');
      const rowIndex = this.get('rowIndex');
      const style = `${typeStylePrefix}${rowIndex}`;
      return style;
    }),
    typeStylePrefix: Ember.computed('stopType', function () {
      switch (this.get('stopType')) {
        case 'pullinGarage':
          return 'garagePullin';

        case 'pulloutGarage':
          return 'garagePullout';

        default:
          return 'pick';
      }
    })
  });

  _exports.default = _default;
});